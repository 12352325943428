import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, DatePicker } from "antd";
import dayjs from "dayjs";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const _t = useTranslate();
    const { form, formProps, saveButtonProps, queryResult } = useForm(
        {
            onMutationError: (data, variables, context, isAutoSave) => {
                const errors = data?.response?.data?.errors;
                if ('email' in errors) {
                    if (errors['email'] === 'emailAlreadyExists')
                        form.setFields([
                            {
                                name: 'email',
                                errors: [_t('users.errors.emailAlreadyExists')],
                            },
                        ]);
                }
            }
        },
    );

    const { selectProps: accountSelectProps } = useSelect({
        resource: "accounts",
        optionLabel: "name",
    });

    const { selectProps: roleSelectProps } = useSelect({
        resource: "roles",
        optionLabel: "name",
    });

    // Force the provider to be email
    form.setFieldsValue(
        {
            'provider': 'email',
            'status': { 'id': 1 }
        });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" form={form}>
                <Form.Item
                    label={_t("users.fields.email")}
                    name={["email"]}
                    rules={[{ required: true, message: _t("forms.errors.required") }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={_t("users.fields.password")}
                    name={["password"]}
                    rules={[{ required: true, message: _t("forms.errors.required") }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label={_t("users.fields.provider")}
                    name={["provider"]}
                    hidden
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={_t("users.fields.firstName")}
                    name={["firstName"]}
                    rules={[{ required: true, message: _t("forms.errors.required") }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={_t("users.fields.lastName")}
                    name={["lastName"]}
                    rules={[{ required: true, message: _t("forms.errors.required") }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={_t("users.fields.role")}
                    name={["role", "id"]}
                    rules={[{ required: true, message: _t("forms.errors.required") }]}
                >
                    <Select {...roleSelectProps} />
                </Form.Item>
                <Form.Item
                    label={_t("users.fields.status")}
                    name={["status", "id"]}
                    hidden
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={_t("users.fields.account")}
                    name={["account", "id"]}
                    rules={[{ required: false }]}
                >
                    <Select {...accountSelectProps} />
                </Form.Item>

            </Form>
        </Create>
    );
};
