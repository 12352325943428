import { Create, Header, useForm, useSelect } from "@refinedev/antd";
import { ErrorComponent, useApiUrl, useCreate, useCreateMany, useGetIdentity, useGo, useMany, useNotification, useOne, useParsed, useTranslate } from "@refinedev/core";
import { Alert, Button, Card, Col, Divider, Flex, Form, Input, InputNumber, Result, Row, Select, Space, Statistic, Typography, Upload, UploadProps } from "antd";
import { useParams, useSearchParams } from "react-router-dom";
import FormRender from "../forms/FormRender";
import { useEffect, useState } from "react";
import { SendOutlined, UploadOutlined } from "@ant-design/icons";
import { FormContainerTitle } from "../forms/components/Container";
import { NotFound } from "../../components/errors";
import { CustomerBackButton } from "./components/customerBackButton";
import { CustomerBreadcrumb } from "./components/customerBreadcrumb";
import { PageLoading } from "../../components/pageLoading";
import { useCredit } from "../../hooks/account";

export const CustomerOrderCreate = () => {
    const _t = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const [formSchema, setFormSchema] = useState([]);
    const identity = useGetIdentity();
    const [user, setUser] = useState({});
    const [success, setSuccess] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const { form, onFinish } = useForm();
    const { mutate } = useCreate();
    const { mutate: mutateMany } = useCreateMany();
    const apiUrl = useApiUrl();
    const go = useGo();
    const { open } = useNotification();
    const { id } = useParsed();
    const [products, setProducts] = useState([]);
    const [productsAmounts, setProductsAmounts] = useState([]);
    const { Title } = Typography;
    const accountCredit = useCredit();
    const [isPrepaid, setIsPrepaid] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [productOptions, setProductOptions] = useState([]);

    const { selectProps: productList } = useSelect({
        resource: "products",
        optionLabel: "id",
        optionValue: "price",
    });

    const { selectProps: productSelectProps, queryResult: productQueryResult } = useSelect({
        resource: "products",
        /*optionLabel: (option) => {
            if (isPrepaid) {
                return `${option.name} - ${option.price} ${_t("credits.credits")}`;
            } else {
                return `${option.name}`;
            }
        },*/
        optionValue: "id",
        searchField: "name",
        sorters: [
            {
                field: "name",
                order: "asc",
            },
        ],
    });


    useEffect(() => {
        console.log("yeeeeeee");
        if (productQueryResult.data && productOptions.length === 0) {
            let groupedOptions = productQueryResult.data?.data.reduce((groups, item) => {
                let group = `${item.group}`; // replace with your actual group property
                if (group == "null") {
                    group = ""
                }
                if (!groups[group]) {
                    groups[group] = { label: group, options: [] };
                }
                groups[group].options.push({
                    label: isPrepaid ? `${item.name} - ${item.price} ${_t("credits.credits")}` : `${item.name}`,
                    value: item.id,
                });
                return groups;
            }, {});
            //console.log("Grouped options", JSON.stringify(groupedOptions));
            const sortedGroupedOptions = Object.entries(groupedOptions)
                .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                .reduce((sortedObj, [key, value]) => {
                    sortedObj[key] = value;
                    return sortedObj;
                }, {});
            //console.log("sortedGroupedOptions ", JSON.stringify(sortedGroupedOptions));
            // Use sortedGroupedOptions instead of groupedOptions
            setProductOptions(Object.values(sortedGroupedOptions));
        }
    }, [productQueryResult, isPrepaid]);

    useEffect(() => {

    }, []);


    const formId = searchParams.get('form');
    //const account = searchParams.get('account');
    if (!formId) {
        return <NotFound />;
    }
    //console.log(searchParams.get('product'), searchParams.get('account'));


    useEffect(() => {
        if (identity.data) {
            setUser(identity.data);
            console.log("User info", identity.data);
            console.log("Account data", identity.data.account);
            setIsPrepaid(user?.account?.billingMode === "prepaid");
        }
    }, [identity]);

    const formQuery = useOne({
        resource: "forms",
        id: formId,
    });

    useEffect(() => {
        if (formQuery.data?.data && formSchema.length === 0) {
            const formData = formQuery.data?.data;
            const sc = JSON.parse(formData?.schema);
            console.log("Form schema", sc);
            setFormSchema(sc);
        }
    }, [formQuery]);

    useEffect(() => {
        productList && console.log("Product list", productList);
    }, [productList]);


    if (formQuery.isLoading /*|| accountQuery.isLoading*/) {
        return <PageLoading />;
    }

    if (formQuery.isError /*|| accountQuery.isError*/) {
        return <NotFound />;
    }

    if (formQuery.isSuccess) {
        console.log("Form data", formQuery.data);
        if (!formQuery.data?.data) {
            return <NotFound />;
        }
    }

    const onSubmit = async (values) => {
        //console.log("Form values", values);
        const mapped = formSchema.map((item: any) => {
            if (item.children?.length > 0) {
                return {
                    ...item,
                    children: item.children.map((child: any) => {
                        return {
                            ...child,
                            value: values[child.name],
                        };
                    }),
                };
            }
            return {
                ...item,
                value: values[item.name],
            };
        });

        const order = {
            productIds: products.map((v: number) => v.toString()),
            accountId: `${user.account.id}`,
            createdBy: `${user.id}`,
            payload: JSON.stringify(mapped),
            status: "submitted",
            comments: values.comments,
        };

        mutate({
            resource: "orders",
            values: order,
        }, {
            onSuccess: (data) => {
                console.log("Order created successfully", data);
                const order = data.data;
                /*go({
                    to: "/account-home",
                });*/
                const attachmentList: any[] = uploadedFiles.map((file) => {
                    return {
                        name: file.name,
                        fileId: file.fileId,
                        metadata: file.metadata,
                        orderId: `${order.id}`,
                        direction: 'in'
                    };
                });

                if (attachmentList.length > 0) {

                    mutateMany({
                        resource: 'attachments',
                        values: attachmentList,
                    }, {
                        onSuccess: () => {
                            console.log("Attachment created successfully");
                            setSuccess(true);
                        },
                        onError: () => {
                            console.log("Attachment creation failed");
                            open?.({
                                message: _t("orders.errors.createAttachment"),
                                type: "error",
                                key: "unique-id",
                                duration: 0,
                            });
                        }
                    });
                } else {
                    setSuccess(true);
                }
            },
        });

        console.log("Mapped", mapped);

    }

    const fileUploadProps: UploadProps = {
        name: 'file',
        action: apiUrl + '/files/upload',
        method: 'POST',
        /*headers: {
            authorization: 'Bearer ' + authToken,
        },*/
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                //message.success(`${info.file.name} file uploaded successfully`);
                console.log("File uploaded successfully");
                console.log(info.file);
                const res = info.file;
                if (res) {
                    console.log("File uploaded successfully", res.response.file);
                    //res.file.path
                    const newFile = {
                        name: res.name,
                        metadata: JSON.stringify({
                            type: res.type,
                            size: res.size
                        }),
                        fileId: res.response.file.id,
                    }
                    setUploadedFiles([...uploadedFiles, newFile]);
                    /*const attachment = {
                        name: res.name,
                        fileId: res.response.file.id,
                        metadata: JSON.stringify(metadata),
                        orderId: `${record.id}`,
                    };
                    mutate({
                        resource: 'attachments',
                        values: attachment,
                        onSuccess: () => {
                            console.log("Attachment created successfully");
                        }
                    });*/
                }
            } else if (info.file.status === 'error') {
                //message.error(`${info.file.name} file upload failed.`);
                if (info.file.error.status == 401) {
                    // Unauthorized, try refreshing token

                }
                console.log("File upload failed",);
            }
        },
    };

    const handleProductChange = (values: any[]) => {
        console.log("Product selected", values);
        setProducts(values);
        const prods = productList?.options?.map((p: any) => {
            return {
                price: p.value,
                id: p.label,
            };
        });
        console.log("Productz", prods);
        const total = values.reduce((acc, curr) => {
            const product = prods.find((p: any) => p.id === curr);
            console.log("Productz2", product);
            return product.price ? acc + product.price : acc;
        }, 0);
        setTotalAmount(total);
    }


    const CardTitle = () => {
        return (
            <div className="text-center px-6 pt-0 pb-1">
                <div className="mb-4">
                    <img src="/images/createorder.png" alt="logo" width={80} />
                </div>
            </div>
        );
    }

    return (
        <>
            {success ? (
                < Row
                    justify="center" align="middle" style={{ minHeight: '80vh' }}
                >
                    <Col className="sm:w-100 md:w-1/2">
                        <Card className="mt-16 text-center">
                            <Result
                                status="success"
                                className={success ? "block" : "hidden"}
                                title={_t('orders.titles.successMessage')}
                                subTitle={_t('orders.titles.successSubMessage')}
                                extra={
                                    <Button type="primary" href="/account-home">
                                        {_t('orders.titles.backHome')}
                                    </Button>
                                }
                            />
                        </Card>
                    </Col>
                </Row>
            ) : (
                <>
                    <Row
                        justify="center"
                        align="middle"
                        className="pt-0"
                        style={{
                            minHeight: "50dvh",
                        }}
                    >
                        <Col xs={22}>
                            <Flex vertical>
                                <CustomerBreadcrumb title={_t('actions.create')} />
                                <Flex className="my-2">
                                    <CustomerBackButton />
                                    <Title level={4}>{_t('orders.titles.create')}</Title>
                                </Flex>
                            </Flex>
                            <Card
                                className="shadow-sm p-0"
                            >
                                <CardTitle />
                                <Form
                                    form={form}
                                    onFinish={onSubmit}
                                >
                                    <FormRender schema={formSchema} />

                                    <FormContainerTitle title={_t('orders.fields.reprogramming')} />

                                    <Flex align="flex-start" vertical gap={16}>
                                        <Select
                                            placeholder={_t('orders.titles.selectProducts')}
                                            className="w-1/2"
                                            mode="multiple"
                                            allowClear
                                            onChange={handleProductChange}
                                            options={productOptions}
                                        />
                                        <Form.Item
                                            name="comments"
                                            className="w-full"
                                        >
                                            <Input.TextArea
                                                rows={3}
                                                placeholder={_t('orders.fields.comments')}
                                            />
                                        </Form.Item>
                                    </Flex>

                                    <div>
                                        <FormContainerTitle title={_t('orders.fields.downloads')} />
                                        <Flex gap={8} wrap="wrap" justify="center">
                                            <Upload
                                                {...fileUploadProps}
                                            >
                                                <Button icon={<UploadOutlined />}>{_t('orders.titles.upload')}</Button>
                                            </Upload>
                                        </Flex>
                                    </div>

                                    <Divider />

                                    {isPrepaid && (
                                        <Flex align="center" className="text-center" vertical>
                                            <Statistic
                                                title={_t('orders.fields.totalAmount')}
                                                value={totalAmount}
                                                precision={0}
                                            />

                                            {totalAmount > accountCredit && (
                                                <Alert
                                                    message={_t('orders.titles.insufficientCredit')}
                                                    type="info"
                                                    className="max-w-xl"
                                                    showIcon
                                                />
                                            )}
                                        </Flex>
                                    )}

                                    <Flex justify="center" className="mt-8">
                                        <Button type="primary" size="large" htmlType="submit" icon={<SendOutlined />}
                                            disabled={(isPrepaid && totalAmount > accountCredit) || products.length === 0 || uploadedFiles.length === 0}
                                        >
                                            {_t("orders.titles.sendOrder")}
                                        </Button>
                                    </Flex>
                                </Form>
                            </Card>
                        </Col>
                    </Row>

                </>
            )}
        </>
    );
}