import { fromJSON } from "postcss";

export enum FieldDataTypes {
    STRING = 'string',
    NUMBER = 'number',
    BOOLEAN = 'boolean',
    DATE = 'date'
}

export interface Field {
    id?: string;
    name?: string;
    description?: string;
    label?: string;
    dataType?: FieldDataTypes;
    required?: boolean;
    defaultValue?: any;
    formTag?: string;
    parent?: Field | null;

    render: () => JSX.Element;
    renderEditor: (field: Field) => JSX.Element;
    renderPropertiesEditor: (field: Field, updateField: any) => JSX.Element;
    fromJSON: (json: any, parent: Field | null) => Field;
    toJSON: () => any;
}