import { CheckCircleFilled, ClockCircleOutlined, PauseCircleOutlined, StopOutlined, SyncOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Tag } from "antd";

export const AccountStatusLabels = ({ value, size = '' }) => {
    const _t = useTranslate();
    const label = _t(`accounts.fields.statusValues.${value}`);

    if (value === "draft")
        return (<Tag icon={<ClockCircleOutlined />} color="default" className={`font-bold ${size}`}>{label}</Tag>)

    if (value === "suspended")
        return (<Tag icon={<PauseCircleOutlined />} color="processing" className={`font-bold ${size}`}>{label}</Tag>)

    if (value === "inactive")
        return (<Tag icon={<StopOutlined />} color="error" className={`font-bold ${size}`}>{label}</Tag>)

    if (value === "active")
        return (<Tag icon={<CheckCircleFilled />} color="success" className={`font-bold ${size}`}>{label}</Tag>)

    return (<Tag>{label}</Tag>)
};

