import React, { useEffect } from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input } from "antd";
import FormEditor from "./FormEditor";

export const FormEdit: React.FC<IResourceComponentsProps> = () => {
    const _t = useTranslate();
    const { form, formProps, saveButtonProps, queryResult } = useForm();

    const formsData = queryResult?.data?.data;
    const [initForm, setInitForm] = React.useState(formsData?.schema);
    console.log("Forms data", formsData);

    const setFormCode = (code: string) => {
        console.log("here", code);
        form.setFieldValue('schema', code);
    }

    useEffect(() => {
        setInitForm(queryResult?.data?.data?.schema);
    }, [queryResult]);

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label={_t("forms.fields.name")}
                    name="name"
                    className="w-60"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={_t("forms.fields.schema")}
                    name="schema"
                    hidden
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            </Form>
            <FormEditor initForm={initForm} setForm={setFormCode} />
        </Edit>
    );
};
