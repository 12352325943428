import React, { useEffect, useState } from 'react';
import { Tree } from 'antd';
import type { TreeDataNode, TreeProps } from 'antd';

const x = 3;
const y = 2;
const z = 1;
const defaultData: TreeDataNode[] = [];

const FormTree = ({ data, setData, selected, setSelected }) => {

    const [gData, setGData] = useState(defaultData);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [flattenedData, setFlattenedData] = useState([]);
    const [expandedKeys, setExpandedKeys] = useState([]);

    useEffect(() => {
        const transformed = data.map((item, index) => {
            return {
                title: item.label,
                key: item.id,
                children: item.children?.map((child, childIndex) => {
                    return {
                        title: child.label,
                        key: child.id,
                        isLeaf: true
                    }
                })
            }
        });
        const keys = data.map(item => item.id);
        console.log('keys', keys);
        setExpandedKeys(keys);
        setGData(transformed);
        setFlattenedData(flattenData(data));

    }, [data]);

    useEffect(() => {
        if (selected) {
            console.log('selected', selected);
            setSelectedKeys([selected.id]);

        }
    }, [selected]);

    const onDragEnter: TreeProps['onDragEnter'] = (info) => {
        console.log(info);
        // expandedKeys, set it when controlled is needed
        // setExpandedKeys(info.expandedKeys)
    };

    const onNodeSelected = (selectedKeys, info) => {
        console.log('selectedKeys', selectedKeys);
        console.log('info', info);
        console.log('flattenedData', flattenedData);
        const selected = flattenedData.find(d => d.id === selectedKeys[0]);
        setSelected(selected);
    }

    const onDrop: TreeProps['onDrop'] = (info) => {
        console.log(info);
        const dropKey = info.node.key;
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]); // the drop position relative to the drop node, inside 0, top -1, bottom 1

        const loop = (
            data: TreeDataNode[],
            key: React.Key,
            callback: (node: TreeDataNode, i: number, data: TreeDataNode[]) => void,
        ) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].key === key) {
                    return callback(data[i], i, data);
                }
                if (data[i].children) {
                    loop(data[i].children!, key, callback);
                }
            }
        };
        const newData = [...gData];

        // Find dragObject
        let dragObj: TreeDataNode;
        loop(newData, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });

        // Cancel drop if both nodes have children
        if (dragObj.children && info.node.children) {
            return;
        }

        if (!info.dropToGap) {
            // Drop on the content
            loop(newData, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert. New item was inserted to the start of the array in this example, but can be anywhere
                item.children.unshift(dragObj);
            });
        } else {
            let ar: TreeDataNode[] = [];
            let i: number;
            loop(newData, dropKey, (_item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                // Drop on the top of the drop node
                ar.splice(i!, 0, dragObj!);
            } else {
                // Drop on the bottom of the drop node
                ar.splice(i! + 1, 0, dragObj!);
            }
        }

        setGData(newData);

        const transformed = newData.map((item, index) => {
            console.log('item', item);
            let parent = flattenedData.find((d) => d.id === item.key);
            if (!parent) {
                console.error('Could not find item with id', item.key);
                return;
            }
            parent.children = item.children?.map((child, childIndex) => {
                const childFound = flattenedData.find((c) => c.id === child.key);
                childFound.parent = parent;
                return childFound;
            });
            return parent;
        });
        console.log('transformed', transformed);
        setData(transformed);
    };

    const flattenData = (data) => {
        return data.reduce((flat, item) => {
            flat.push(item);
            if (item.children) {
                flat.push(...flattenData(item.children));
            }
            return flat;
        }, []);
    }

    return (
        <Tree
            className="draggable-tree"
            expandedKeys={expandedKeys}
            draggable
            blockNode
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onSelect={onNodeSelected}
            treeData={gData}
            selectedKeys={selectedKeys}
        />
    );
}

export default FormTree;