import { useTranslate } from "@refinedev/core";
import { Form, Input, Button, Col, Row, Card, Spin, Result, notification, Layout } from 'antd';

export const PasswordField = () => {
    const _t = useTranslate();
    return (
        <Form.Item
            name="password"
            label={_t("pages.register.fields.password")}
            tooltip={_t("pages.register.fields.passwordTooltip")}
            rules={
                [
                    {
                        required: true, message: _t("pages.register.errors.required")
                    },
                    {
                        min: 8, message: _t("pages.register.errors.passwordMinLength")
                    },
                    {
                        pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])'), message: _t("pages.register.errors.passwordComplexity")
                    }
                ]}
        >
            <Input size="large" type="password" placeholder="********" />
        </Form.Item >
    );
};

export const ConfiormPasswordField = () => {
    const _t = useTranslate();
    return (
        <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            label={_t("pages.register.fields.confirmPassword")}
            rules={[
                {
                    required: true, message: _t("pages.register.errors.required")
                },
                ({ getFieldValue }) => ({
                    validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(_t("pages.register.errors.passwordMismatch"));
                    }
                })
            ]}
        >
            <Input size="large" type="password" placeholder="********"/>
        </Form.Item>
    )
}