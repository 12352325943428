import { useTranslate } from "@refinedev/core";
import { Breadcrumb } from "antd";

export const CustomerBreadcrumb = ({title}) => {
    const _t = useTranslate();
    return (
        <>
            <Breadcrumb items={[
                { title: <a href="/account-home">{_t('titles.home')}</a> },
                { title: title }
            ]} />
        </>
    );
}