import { Col, Row, Spin } from "antd";

export const PageLoading = () => {
    return (
        <>
            <Row
                justify="center"
                align="middle"
                style={{
                    minHeight: "80dvh",
                }}
            >
                <Col>
                    <Spin size="large" />
                </Col>
            </Row>
        </>
    );
};
