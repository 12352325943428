import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker, Select, Row, Col } from "antd";
import dayjs from "dayjs";
import { useSelectValues } from "./values";

export const AccountCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm();

  const { accountTypes, billingModes } = useSelectValues();

  return (
    <Create saveButtonProps={saveButtonProps}
      title="Créer un compte client">
      <Form {...formProps} layout="vertical" >
        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label="Nom du client"
              name={["name"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label="Type"
              initialValue={accountTypes[0].value}
              name={["type"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={accountTypes}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label="SIRET"
              name={["legalId"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label="Addresse"
              name={["address"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label="Téléphone"
              name={["phone"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label="Email"
              name={["email"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label="Mode de facturation"
              name={["billingMode"]}
              initialValue={billingModes[0].value}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={billingModes}
              />
            </Form.Item>
            <Form.Item
              name={["status"]}
              initialValue={"active"}
              hidden
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
