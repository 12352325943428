import { Authenticated, CanAccess, GitHubBanner, I18nProvider, Refine, useSetLocale } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { AntdInferencer } from "@refinedev/inferencer/antd";
import {
  useNotificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import axios from "axios";
import {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { App as AntdApp, ConfigProvider } from "antd";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider, axiosInstance } from "./authProvider";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { useTranslation } from "react-i18next";
import { accessControlProvider } from "./accessControlProvider";
import { CustomerRoutes } from "./routes/customerRoutes";
import { AuthRoutes } from "./routes/authRoutes";
import { AdminRoutes } from "./routes/adminRoutes";
import { routerBindings } from "./routes/bindings";
import locale from 'antd/locale/fr_FR';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import dataProvider from "@refinedev/simple-rest";
import { resources } from "./resources";
//import { dataProvider } from "./dataProvider";
dayjs.locale('fr');

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider: I18nProvider = {
    translate: (key: string, params: object) => {
      return t(key, params);
    },
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <AntdApp>
            <DevtoolsProvider>
              <ConfigProvider
                locale={locale}
                theme={{
                  token: {
                    colorPrimary: "#F37233",
                  },
                }}
              >
                <Refine
                  dataProvider={
                    dataProvider(import.meta.env.VITE_API_URL, axiosInstance)
                  }
                  i18nProvider={i18nProvider}
                  notificationProvider={useNotificationProvider}
                  authProvider={authProvider}
                  accessControlProvider={accessControlProvider}
                  routerProvider={routerBindings}
                  resources={resources}
                  options={{
                    syncWithLocation: true,
                    warnWhenUnsavedChanges: true,
                    useNewQueryKeys: true,
                    projectId: "V1IFZY-wHBmsx-lKHJIM",
                  }}
                >
                  <Routes>

                    {CustomerRoutes}

                    {AdminRoutes}

                    {AuthRoutes}

                  </Routes>

                  <RefineKbar />
                  <UnsavedChangesNotifier />
                  <DocumentTitleHandler />
                </Refine>
              </ConfigProvider>
              <DevtoolsPanel />
            </DevtoolsProvider>
          </AntdApp>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
