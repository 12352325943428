import { useTranslate } from "@refinedev/core";
import { Button, Card, Col, Result, Row } from "antd";
import { useEffect, useState } from "react";

export const PaymentResult = () => {
    const _t = useTranslate();
    const [success, setSuccess] = useState<boolean>(true);

    return (
        <>
            {success ? (
                < Row
                    justify="center" align="middle" style={{ minHeight: '80vh' }}
                >
                    <Col className="sm:w-100 md:w-1/2">
                        <Card className="text-center">
                            <Result
                                status="success"
                                className={success ? "block" : "hidden"}
                                title={_t('payments.titles.successMessage')}
                                subTitle={_t('payments.titles.successSubMessage')}
                                extra={[
                                    <Button type="primary" href="/account-home">
                                        {_t('payments.titles.backToHome')}
                                    </Button>,
                                ]}
                            />
                        </Card>
                    </Col>
                </Row>
            ) : (
                <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
                    <Col className="sm:w-100 md:w-1/2">
                        <Card className="text-center">
                            <Result
                                status="error"
                                className={success ? "hidden" : "block"}
                                title={_t('payments.titles.errorMessage')}
                                subTitle={_t('payments.titles.errorSubMessage')}
                            />
                        </Card>
                    </Col>
                </Row>
            )}
        </>
    )
}