import { DateField, TextField } from "@refinedev/antd";
import { useGetIdentity, useTranslate } from "@refinedev/core";
import { Button, Card, Col, Empty, Flex, Row, Space, Typography, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { fullName } from "../../../shared/utils";
import { DownloadOutlined, PaperClipOutlined, UploadOutlined } from "@ant-design/icons";

export const Messages = ({ messages, fileUploadProps, sendMessage, fileList }) => {

    const _t = useTranslate();
    const [message, setMessage] = useState("");
    const user = useGetIdentity();
    const [userName, setUserName] = useState("");

    useEffect(() => {
        if (user?.data) {
            setUserName(`${user.data?.firstName} ${user.data?.lastName}`);
        }
    }, [user]);

    const onSendMessage = () => {
        sendMessage(message);
        setMessage("");
    }

    const MessageContent = ({ message }) => {
        return (
            <Flex vertical gap={'small'}>
                <Typography.Text>
                    {message.content}
                </Typography.Text>
                <Flex gap={"small"} vertical className="mt-2">
                    {message.attachments?.map((attachment, index) => (
                        <Button
                            key={attachment.id}
                            icon={<DownloadOutlined />}
                            type="primary"
                            size="small"
                            href={attachment.file.path}
                            target="_blank"
                        >
                            {_t('attachments.attachment')}
                        </Button>
                    ))}
                </Flex>
            </Flex>
        )
    }

    return (
        <>
            {messages?.length > 0 ? (
                <>
                    {messages.map((message, index) => (
                        <div key={index} className="mb-4">
                            {message.sender?.id === user?.data?.id
                                ?
                                <>
                                    <Flex align="flex-end" vertical>
                                        <Card className="w-2/3 bg-blue-200" size={"small"}>
                                            <MessageContent message={message} />
                                        </Card>

                                        <Space size={4} className="mt-1 px-1 text-xs">
                                            <DateField value={message.createdAt} type="secondary" format="DD/MM/YY HH:mm" className="text-xs" />
                                            - <TextField value={fullName(message.sender)} type="secondary" className="text-xs" />
                                        </Space>
                                    </Flex>
                                </>
                                :
                                <>
                                    <Flex align="flex-start" vertical>
                                        <Card className="w-2/3" size={"small"}>
                                            <MessageContent message={message} />
                                        </Card>

                                        <Space size={4} className="mt-1 px-1 text-xs">
                                            <DateField value={message.createdAt} type="secondary" format="DD/MM/YY HH:mm" className="text-xs" />
                                            - <TextField value={fullName(message.sender)} type="secondary" className="text-xs" />
                                        </Space>
                                    </Flex>
                                </>
                            }
                        </div>
                    ))
                    }
                </>
            ) : (
                <>
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={_t("orders.titles.noMessages")}
                    />
                </>
            )}
            < TextArea
                rows={3}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="border-blue-200 border-solid"
            />

            <Row justify="space-between" className="mt-2">
                <Col span={18} className="text-left">
                    <Upload
                        {...fileUploadProps}
                        showUploadList={true}
                        fileList={fileList}
                    >
                        <Button icon={<PaperClipOutlined />}>

                        </Button>
                    </Upload>
                </Col>
                <Col span={6} className="text-right">
                    <Button
                        disabled={message.length === 0}
                        type="primary"
                        onClick={onSendMessage}>
                        {_t('orders.titles.sendMessage')}
                    </Button>
                </Col>
            </Row>

        </>
    )
};