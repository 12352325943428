import { Button, Divider, Flex, Form, Input, Popconfirm, Radio } from "antd";
import { Field } from "./Field";
import { generateRandomString } from "../../../shared/utils";
import { InputField } from "./InputField";
import { DeleteOutlined } from "@ant-design/icons";

export enum Direction {
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical"
}

export const FormContainerTitle = ({ title }) => (
    <>
        <Divider className="mb-0"/>
        <Flex justify="center">
        <h4 className="text-center font-bold bg-white px-6 mb-6" style={{ marginTop: '-13px' }} >
            {title}
        </h4>
        </Flex>
    </>
)

export class ContainerField implements Field {

    id = generateRandomString(10);
    name = 'Container';
    label = 'Container'
    description = 'A container for grouping fields';
    direction = Direction.HORIZONTAL;
    children: Field[] = [];
    formTag = 'ContainerField';
    childrenPerRow = 3;
    parent = null;

    /*render = () => (
        <div>
            <h4>{this.label}</h4>
            <Flex vertical={this.direction == Direction.VERTICAL} gap={8}>
                {this.children.map(child => (
                    <div key={child.id}
                        style={{ width: `calc(100% / ${this.children.length})` }}
                    >
                        {child.render()}
                    </div>
                ))}
            </Flex>
        </div>
    )*/
    render = () => (
        <div>
            <FormContainerTitle title={this.label} />
            <Flex vertical={this.direction == Direction.VERTICAL} gap={8} wrap="wrap">
                {this.children.map(child => (
                    <div key={child.id}
                        style={{ width: `calc(95% / ${this.childrenPerRow})` }}
                    >
                        {child.render()}
                    </div>
                ))}
            </Flex>
        </div>
    )

    renderEditor = (field, selected, setSelected, deleteComponent) => (
        <ContainerFieldEditor field={field} selected={selected} setSelected={setSelected} deleteComponent={deleteComponent} />
    )

    renderPropertiesEditor = (field: Field, updateField: any) => (
        <ContainerFieldPropsEditor inputField={field} setInputField={updateField} />
    )

    static fromJSON = (json: any, parent = null) => {
        const container = new ContainerField();
        container.id = json.id;
        container.label = json.label;
        container.name = json.name;
        container.description = json.description;
        container.direction = json.direction;
        container.children = json.children.map(child => {
            if (child.formTag === 'InputField') {
                return InputField.fromJSON(child, container);
            }
        });
        //console.log('Container from JSON', container);
        container.parent = parent;
        return container;
    }

    /*toJSON = () => {
        return {
            id: this.id,
            name: this.name,
            description: this.description,
            label: this.label,
            direction: this.direction,
            children: this.children.map(child => child.toJSON()),
            formTag: this.formTag
        }
    }*/
}

export const ContainerFieldEditor = ({ field, selected, setSelected, deleteComponent }) => {

    const onClick = (event, child) => {
        event.stopPropagation();
        console.log('clicked child', child);
        setSelected(child);
    }

    return (
        <div className="p-4 bg-slate-200">

            <Flex justify="space-between">
                <h4>{field.label}</h4>
                <Popconfirm
                    title="Delete the item"
                    description="Are you sure to delete this item?"
                    onConfirm={() => deleteComponent(field)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button size='small' danger icon={<DeleteOutlined />} className="" />
                </Popconfirm>
            </Flex>
            <Flex vertical={field.direction == Direction.VERTICAL} wrap="wrap">
                {field.children.map(
                    child => (
                        <div key={child.id}
                            style={{ width: `calc(100% / ${field.childrenPerRow})` }}
                            onClick={(event) => onClick(event, child)}
                            className={
                                selected === child
                                    ? 'border-blue-300 border-2 border-dashed p-2 mb-2'
                                    : 'hover:border-blue-300 hover:border-1 hover:border-solid p-2 mb-2'
                            }>
                            {child.renderEditor(child, selected, setSelected, deleteComponent)}
                        </div>
                    )
                )}
            </Flex>
        </div>
    );
}

export const ContainerFieldPropsEditor = ({ inputField, setInputField }: { inputField: Field, setInputField: any }) => {

    const handleInputChange = (prop) => (e) => {
        const newVal = { ...inputField, [prop]: e.target.value };
        setInputField(newVal);
    };

    const handleSelectChange = (prop) => (value) => {
        const newVal = { ...inputField, [prop]: value };
        setInputField(newVal);
    };

    const handleCheckboxChange = (prop) => (e) => {
        const newVal = { ...inputField, [prop]: e.target.checked };
        setInputField(newVal);
    };

    const handleRadioChange = (prop) => (e) => {
        const newVal = { ...inputField, [prop]: e.target.value };
        setInputField(newVal);
    }

    return (
        <Form layout="vertical">
            <Form.Item label="Description">
                <Input value={inputField.description} onChange={handleInputChange('description')} />
            </Form.Item>
            <Form.Item label="Label">
                <Input value={inputField.label} onChange={handleInputChange('label')} />
            </Form.Item>
            <Form.Item label="Direction">
                <Radio.Group value={inputField.direction} onChange={handleRadioChange('direction')}>
                    <Radio value={Direction.HORIZONTAL}>Horizontal</Radio>
                    <Radio value={Direction.VERTICAL}>Vertical</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="Items per row">
                <Input value={inputField.childrenPerRow} onChange={handleInputChange('childrenPerRow')} />
            </Form.Item>
        </Form>
    );
};
