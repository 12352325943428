import { AuthPage, ThemedTitleV2 } from "@refinedev/antd";
import { AppIcon } from "../../components/app-icon";
import React from 'react';
import { Form, Input, Button, Col, Row, Card, Spin, Result, notification, Layout } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslate } from "@refinedev/core";
import { PasswordInput } from "antd-password-input-strength";
import axios from "axios";
import { containerStyles } from "../login/styles";
import { faker } from '@faker-js/faker';
import ReCAPTCHA from "react-google-recaptcha";
/*export const Register = () => {
  return (
    <AuthPage
      type="register"
      title={
        <ThemedTitleV2
          collapsed={false}
          text="Refine Project"
          icon={<AppIcon />}
        />
      }
    />
  );
};*/
const min = 1000000000;
const max = 9999999999;

let initialData = {};

if (!import.meta.env.PROD) {
  initialData = {
    "firstName": faker.person.firstName(),
    "lastName": faker.person.lastName(),
    "phone": faker.phone.number(),
    "email": faker.internet.email(),
    "legalId": `${Math.floor(Math.random() * (max - min + 1)) + min}`, //"1234567890",
    "name": faker.company.name(),
    "address": faker.location.streetAddress(),
    "zipCode": faker.location.zipCode(),
    "city": faker.location.city(),
    "password": "Password123!",
    "confirmPassword": "Password123!"
  };
}

const axiosInstance = axios.create({ baseURL: import.meta.env.VITE_API_URL || "http://localhost:3000/api/v1" });
console.log(import.meta.env.VITE_API_URL);

const Register = () => {

  const _t = useTranslate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);
  const [notif, contextHolder] = notification.useNotification();
  const [recaptchaValue, setRecaptchaValue] = React.useState<string | null>(null);

  const onFinish = async (values: any) => {
    console.log('Received values of form: ', values);
    setLoading(true);

    try {
      const accountData = {
        "name": values.name,
        "type": "company",
        "legalId": values.legalId,
        "address": values.address,
        "zipCode": values.zipCode,
        "city": values.city,
        "phone": values.phone,
        "email": values.email,
        "status": "draft",
        "billingMode": "prepaid",
      };

      const checkData = {
        "email": values.email,
        "legalId": values.legalId,
      };
      console.log("Check data", checkData);
      let precheck = await axiosInstance.post('/auth/register/precheck', checkData);
      console.log("Precheck", precheck.data);
      if (!precheck.data.isEmailAvailable) {
        form.setFields([{
          name: "email",
          errors: [_t("pages.register.errors.emailAlreadyExists")]
        }]);
        return;
      }

      if (!precheck.data.isLegalIdAvailable) {
        form.setFields([{
          name: "legalId",
          errors: [_t("pages.register.errors.accountAlreadyExists")]
        }]);
        return;
      }

      console.log("Account data", accountData);

      let response = await axiosInstance.post('/auth/account/register', accountData);
      const accountId = response.data.id;
      if (!accountId)
        throw new Error("Account creation failed");

      console.log("Account created", accountId);

      const userData = {
        "firstName": values.firstName,
        "lastName": values.lastName,
        "email": values.email,
        "phone": values.phone,
        "password": values.password,
        "account": { id: accountId },
      };

      response = await axiosInstance.post('/auth/email/register', userData);
      setSuccess(true);
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        //console.log(error.response.data);
        //console.log(error.response.status);
        //console.log(error.response.headers);

        if (error.response.status == 422) {
          if (error.response?.data?.errors?.email === "emailAlreadyExists") {
            form.setFields([{
              name: "email",
              errors: [_t("pages.register.errors.emailAlreadyExists")]
            }]);
          }
          if (error.response?.data?.errors?.email === "accountAlreadyExists") {
            form.setFields([{
              name: "legalId",
              errors: [_t("pages.register.errors.accountAlreadyExists")]
            }]);
          }
        } else {
          notif.error({
            message: _t("pages.register.errors.title"),
            description: _t("pages.register.errors.unknown"),
            duration: 0
          });
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const onRecaptchaChange = (value: string | null) => {
    setRecaptchaValue(value);
    console.log("Captcha value:", value);
  }

  return (
    <>
      {contextHolder}
      {success ? (
        < Row
          justify="center" align="middle" style={{ minHeight: '100vh' }}
        >
          <Col className="sm:w-100 md:w-1/2">
            <Card className="mt-16 text-center">
              <Result
                status="success"
                className={success ? "block" : "hidden"}
                title={_t('pages.register.successMessage')}
                subTitle={_t('pages.register.successSubMessage')}
              />
            </Card>
          </Col>
        </Row>
      ) : (
        <Layout>
          < Row
            justify="center" align="middle"
            className="shadow-xl rounded-lg p-4"
            style={{
              minHeight: '100dvh',
            }}
          >
            <Col className="sm:w-100 md:w-3/4 lg:w-3/4 2xl:w-1/2">
              <Row gutter={16} className="pt-4">
                <Col span={24} className="text-center">
                  <img src="/images/logo.png" alt="Refine" width={200} />
                </Col>
              </Row>
              <Card
                title={_t('pages.register.helpMessage')} className="mt-16"

              >
                <Form
                  form={form}
                  name="register"
                  className="register-form"
                  layout="vertical"
                  initialValues={initialData}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="firstName"
                        label={_t("users.fields.firstName")}
                        rules={[{ required: true, message: _t("pages.register.errors.required") }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="lastName"
                        label={_t("users.fields.lastName")}
                        rules={[{ required: true, message: _t("pages.register.errors.required") }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="phone"
                        label={_t("accounts.fields.phone")}
                        rules={[{ required: true, message: _t("pages.register.errors.required") }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="email"
                        label={_t("users.fields.email")}
                        rules={[{ required: true, type: 'email', message: _t("pages.register.errors.invalidEmail") }]}
                      >
                        <Input size="large" placeholder="email@example.com" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="legalId"
                        label={_t("accounts.fields.legalId")}
                        rules={[{ required: true, message: _t("pages.register.errors.required") }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label={_t("accounts.fields.name")}
                        rules={[{ required: true, message: _t("pages.register.errors.required") }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col sm={24} md={12}>
                      <Form.Item
                        name="address"
                        label={_t("accounts.fields.address")}
                        rules={[{ required: true, message: _t("pages.register.errors.required") }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} lg={4}>
                      <Form.Item
                        name="zipCode"
                        label={_t("accounts.fields.zipCode")}
                        rules={[{ required: true, message: _t("pages.register.errors.required") }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                    <Col sm={12} lg={8}>
                      <Form.Item
                        name="city"
                        label={_t("accounts.fields.city")}
                        rules={[{ required: true, message: _t("pages.register.errors.required") }]}
                      >
                        <Input size="large" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name="password"
                        label={_t("pages.register.fields.password")}
                        tooltip={_t("pages.register.fields.passwordTooltip")}
                        rules={[
                          {
                            required: true, message: _t("pages.register.errors.required")
                          },
                          {
                            min: 8, message: _t("pages.register.errors.passwordMinLength")
                          },
                          {
                            pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])'), message: _t("pages.register.errors.passwordComplexity")
                          }
                        ]}
                      >
                        <Input size="large" type="password" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="confirmPassword"
                        dependencies={['password']}
                        label={_t("pages.register.fields.confirmPassword")}
                        rules={[
                          {
                            required: true, message: _t("pages.register.errors.required")
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(_t("pages.register.errors.passwordMismatch"));
                            }
                          })
                        ]}
                      >
                        <Input size="large" type="password" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <ReCAPTCHA
                        sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                        onChange={onRecaptchaChange}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} className="pt-4">
                    <Col span={24}>
                      <Form.Item>
                        <Spin spinning={loading}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            block
                            disabled={!recaptchaValue}
                          >
                            {_t('pages.register.buttons.submit')}
                          </Button>
                        </Spin>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row >
        </Layout>
      )}
    </>

  );
};

export default Register;