import { SearchOutlined } from "@ant-design/icons";
import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Form, Input, Select, Button, DatePicker, Flex } from "antd";

const { RangePicker } = DatePicker;

const AccountSelect = () => {
    const { selectProps } = useSelect({
        resource: "accounts",
        optionLabel: "name",
    });
    console.log("Account select props", selectProps);
    return (
        <Form.Item
            label="Client"
            name="account"
        >
            <Select
                {...selectProps}
                allowClear
                size="small"
                placeholder="Sélectionner un client"
                className="min-w-96"
            />
        </Form.Item>
    );
}

export const ListFilter = ({ formProps, isAdmin }) => {

    const _t = useTranslate();

    return (
        <Form layout="inline" {...formProps}>
            <Flex>
                <Form.Item label="Search" name="q" className="hidden">
                    <Input
                        size="small"
                        placeholder="ID, Title, Content, etc."
                        prefix={<SearchOutlined />}
                    />
                </Form.Item>
                <Form.Item
                    label={_t("fields.date")}
                    name="createdAt"
                >
                    <RangePicker size="small" />
                </Form.Item>
                <Form.Item
                    label={_t("orders.fields.status")}
                    name="status"
                >
                    <Select
                        allowClear
                        size="small"
                        className="min-w-32"
                        options={[
                            {
                                label: _t("orders.fields.statusValues.submitted"),
                                value: "submitted",
                            },
                            {
                                label: _t("orders.fields.statusValues.ongoing"),
                                value: "ongoing",
                            },
                            {
                                label: _t("orders.fields.statusValues.completed"),
                                value: "completed",
                            },
                            {
                                label: _t("orders.fields.statusValues.canceled"),
                                value: "canceled",
                            },
                        ]}
                        placeholder={_t("orders.fields.status")}
                    />
                </Form.Item>

                <Form.Item
                    label={_t('orders.fields.vin')}
                    name="vin"
                >
                    <Input
                        size="small"
                        placeholder={_t('orders.fields.vin')}
                    />
                </Form.Item>

                {!isAdmin && (<>
                    <Form.Item>
                        <Button htmlType="submit" type="primary" size="small">
                            {_t("buttons.filter")}
                        </Button>
                    </Form.Item>
                </>)}
            </Flex>
            
            <Flex>
                {isAdmin && (<>
                    <AccountSelect />
                    <Form.Item
                        label={_t('accounts.fields.billingMode')}
                        name="billingMode"
                    >
                        <Select
                            allowClear
                            size="small"
                            className="min-w-32"
                            options={[
                                {
                                    label: _t("accounts.fields.billingModeValues.prepaid"),
                                    value: "prepaid",
                                },
                                {
                                    label: _t("accounts.fields.billingModeValues.postpaid"),
                                    value: "postpaid",
                                },
                                {
                                    label: _t("accounts.fields.billingModeValues.free"),
                                    value: "free",
                                },
                            ]}
                            placeholder={_t('accounts.fields.billingMode')}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" type="primary" size="small">
                            {_t("buttons.filter")}
                        </Button>
                    </Form.Item>
                </>)}
            </Flex>
        </Form>
    );
};