import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Flex, Form, Input, Layout, List, Modal, Popconfirm, Select, Typography, theme } from "antd";
import { InputField, InputFieldEditor, InputFieldPropsEditor } from './components/InputField';
import { Field } from './components/Field';
import { generateRandomString } from '../../shared/utils';
import { ContainerField } from './components/Container';
import { CodeOutlined, FormOutlined } from '@ant-design/icons';
import FormTree from './components/FormTree';
import { useTranslate } from '@refinedev/core';
const { Header, Content, Footer, Sider } = Layout;


const defaultStyle: React.CSSProperties = {
    backgroundColor: '#fff',
};

const json2 = '[{"id":"l4ksowTzgl","name":"InputFieldA","description":"The basic input field","label":"Input","dataType":"string","required":true,"defaultValue":"","formTag":"InputField"},{"id":"Z3BsWqOIYI","name":"InputFieldB","description":"The basic input field","label":"Input","dataType":"string","required":true,"defaultValue":"","formTag":"InputField"}]';
const json = '[{"id":"565mdy1Ajq","name":"Container","description":"A container for grouping fields","label":"Container","direction":"horizontal","children":[{"id":"D0vTo4mxzR","name":"InputField","description":"The basic input field","label":"Input","dataType":"string","required":true,"defaultValue":"","formTag":"InputField","parent":"565mdy1Ajq"}],"formTag":"ContainerField"}]';

const FormEditor = ({ initForm, setForm }) => {

    const [components, setComponents] = React.useState([]);
    const [selectedComponent, setSelectedComponent] = React.useState(null);
    const [showProperties, setShowProperties] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formCode, setFormCode] = useState('');
    const [editorCode, setEditorCode] = useState('');
    const { Paragraph, Text } = Typography;
    const _t = useTranslate();

    const templateComps = [
        new ContainerField(),
        new InputField(true),
    ]

    const createComponents = (json) => {
        const parsedJson = JSON.parse(json);
        return parsedJson.map((comp: any) => {
            if (comp.formTag === 'InputField') {
                return InputField.fromJSON(comp);
            }
            if (comp.formTag === 'ContainerField') {
                return ContainerField.fromJSON(comp);
            }
        });
    }

    useEffect(() => {
        console.log('Init form', initForm);
        if (initForm) {
            /*const parsedJson = JSON.parse(initForm);
            const comps = parsedJson.map((comp: any) => {
                if (comp.formTag === 'InputField') {
                    return InputField.fromJSON(comp);
                }
                if (comp.formTag === 'ContainerField') {
                    return ContainerField.fromJSON(comp);
                }
            });*/
            const comps = createComponents(initForm);
            setComponents(comps);
            setFormCode(getFormCode(comps, true));
            setEditorCode(getFormCode(comps, true));
        }
    }, [initForm]);

    const addComponent = (component) => {
        let newC: any = [];
        if (selectedComponent?.formTag === 'ContainerField') {
            // Nest the component in the selected container
            const container = (selectedComponent as ContainerField);
            component.parent = container;
            container.children = [...container.children, component];
            newC = components.map(
                item => item.id == container.id ? container : item
            );
            console.log("Items", newC);
        } else if (selectedComponent?.parent?.formTag === 'ContainerField') {
            console.log('The selected component is nested in a Container.');
            const container = (selectedComponent.parent as ContainerField);
            component.parent = container;
            container.children = [...container.children, component];
            newC = components.map(
                item => item.id == container.id ? container : item
            );
        } else {
            newC = [...components, component];
        }

        setComponents(newC);
        setSelectedComponent(component);
        setShowProperties(true);
        setForm(getFormCode(newC, false));
        setFormCode(getFormCode(newC, true));
        setEditorCode(getFormCode(newC, true));
    }

    const deleteComponent = (component) => {
        console.log('Delete component', component);
        let newC: any = [];
        if (component.parent && component.parent instanceof ContainerField) {
            console.log('The selected component is nested in a Container.');
            const container = component.parent as ContainerField;
            container.children = container.children.filter(
                child => child.id !== component.id
            );
            newC = components.map(
                item => item.id == container.id ? container : item
            );
        } else {
            newC = components.filter(
                item => item.id !== component.id
            );
        }
        setComponents(newC);
        setSelectedComponent(null);
        setShowProperties(false);
        setForm(getFormCode(newC, false));
        setFormCode(getFormCode(newC, true));
        setEditorCode(getFormCode(newC, true));
    }

    const onComponentClick = (event, component) => {
        console.log(event.target);
        console.log(component);
        setSelectedComponent(component);
        setShowProperties(true);
    }

    const getFormCode = (toSerialize: Field[], formatted = false) => {
        console.log('getFormCode', components, toSerialize);
        if (toSerialize) {
            console.log('To serialize', toSerialize);
            return formatted ? JSON.stringify(toSerialize, null, 2) : JSON.stringify(toSerialize);
        }
        else
            return formatted ? JSON.stringify(components, null, 2) : JSON.stringify(components);
    }

    const updateSelectedComponent = (updated: Field) => {
        console.log('updateSelectedComponent', selectedComponent, updated);
        let newC = [];
        if (selectedComponent?.parent && selectedComponent?.parent instanceof ContainerField) {
            const container = selectedComponent.parent as ContainerField;
            container.children = container.children.map(
                child => child.id == updated.id ? updated : child
            );
            newC = components.map(
                item => item.id == container.id ? container : item
            );
        } else {
            newC = components.map(
                component => component.id == selectedComponent.id ? updated : component
            );
        }
        //console.log("updateSelectedComponent", newC[0], JSON.stringify(newC[0]));
        setFormCode(getFormCode(newC, true));
        setEditorCode(getFormCode(newC, true));
        setForm(getFormCode(newC, false));
        setComponents(newC);
        setSelectedComponent(updated);
    }

    const unselectComponent = (event) => {
        console.log(event.target.id);
        if (event.target.id !== 'form-editor') return;
        setSelectedComponent(null);
        setShowProperties(false);
    }

    const setSelectedComponentEx = (component) => {
        setSelectedComponent(component);
        setShowProperties(true);
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const setReorderedData = (data) => {
        console.log('Reordered data', data);
        setComponents(data);
        setFormCode(getFormCode(data, true));
        setEditorCode(getFormCode(data, true));
        setForm(getFormCode(data, false));
    }

    const handleSaveFormCode = () => {
        setIsModalOpen(false);
        const comps = createComponents(editorCode);
        setComponents(comps);
        setFormCode(editorCode);
        setForm(editorCode);
        //setForm(getFormCode(components, false)); 
        
        console.log('Form code', editorCode);
    }

    return (
        <Layout style={defaultStyle}>
            <Sider width={250} style={defaultStyle}>
                <Flex className='border-l-0 border-y-0 border-r-1 border-solid border-gray-200 px-4 min-h-96'
                    vertical justify='space-between'>
                    <List
                        header={<h3>Form components</h3>}
                        size='small'
                        dataSource={templateComps}
                        renderItem={(component) => (
                            <List.Item actions={
                                [
                                    <Button type="link" onClick={() => addComponent(component)}>Add +</Button>
                                ]
                            }>
                                <div>
                                    {component.name}
                                </div>
                            </List.Item>

                        )}
                    />
                    <Divider className='my-1' />
                    <h3 className='mt-2 mb-0'>Hierarchy</h3>
                    <Divider className='mt-0 mb-2' />
                    <FormTree
                        data={components}
                        setData={setReorderedData}
                        selected={selectedComponent}
                        setSelected={setSelectedComponent}
                    />
                    <Button size='small' icon={<CodeOutlined />} onClick={showModal} />
                </Flex>

            </Sider>

            <Content style={defaultStyle}>
                <Layout style={defaultStyle}>
                    <Content
                        id='form-editor'
                        className='p-4 min-h-3'
                        onClick={(event) => unselectComponent(event)}
                    >
                        <div>
                            <Form layout="vertical">
                                {components.map((component: Field, index) => (
                                    <div key={component.id}
                                        className={
                                            selectedComponent === component
                                                ? 'border-blue-300 border-2 border-dashed p-2 mb-2'
                                                : 'hover:border-blue-300 hover:border-1 hover:border-solid p-2 mb-2'
                                        }
                                        onClick={(event) => onComponentClick(event, component)}
                                    >
                                        <div className="">
                                            {component?.renderEditor(
                                                component, selectedComponent, setSelectedComponentEx, deleteComponent)}
                                        </div>
                                    </div>
                                ))}
                            </Form>
                        </div>
                    </Content>
                    {showProperties && (
                        <div className='border-r-0 border-y-0 border-l-1 border-solid border-gray-200 px-4 min-h-96'>
                            <Sider width={200}>
                                <div className='bg-white p-2'>
                                    <h3>Properties</h3>
                                    <Divider className='mt-1 mb-4' />
                                    <div>
                                        {selectedComponent?.renderPropertiesEditor(selectedComponent, updateSelectedComponent)}
                                    </div>
                                </div>
                            </Sider>
                        </div>
                    )}
                </Layout>
            </Content>
            <Modal
                title="Code"
                open={isModalOpen}
                footer={[
                    <Button key="submit" type="primary" onClick={handleSaveFormCode}>
                        Enregistrer
                    </Button>
                ]}
                onCancel={handleCancel}
                width={800}
            >
                <Text copyable className="hidden">
                    <pre className='text-xs'>
                        {formCode}
                    </pre>
                </Text>

                <Input.TextArea
                    rows={30}
                    value={editorCode}
                    onChange={(val) => setEditorCode(val.target.value)}>
                </Input.TextArea>

            </Modal>
        </Layout>
    );
}

export default FormEditor;