import React from "react";
import {
    IResourceComponentsProps,
    BaseRecord,
    useTranslate,
    useApiUrl,
    useCreate,
    useUpdate,
    useInvalidate,
    useGetIdentity,
} from "@refinedev/core";
import { useTable, List, ShowButton, DateField, DeleteButton } from "@refinedev/antd";
import { Table, Space, Button, Upload, UploadProps } from "antd";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { useAdmin } from "../../hooks/auth";
import { CustomerBackButton } from "../orders/components/customerBackButton";
import { CustomerBreadcrumb } from "../orders/components/customerBreadcrumb";
import { UserRole } from "../../models/role";

export const TransactionList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const apiUrl = useApiUrl();
    const { mutate: create } = useCreate();
    const { mutate: update } = useUpdate();
    const invalidate = useInvalidate();
    const isAdmin = useAdmin();
    const { data: user } = useGetIdentity();
    const { tableProps } = useTable({
        syncWithLocation: true,
        resource: "transactions",
    });

    const createUploadProps = (transactionId: number, accountId: number) => ({
        name: 'file',
        action: apiUrl + '/files/upload',
        method: 'POST',
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                //message.success(`${info.file.name} file uploaded successfully`);
                console.log(info.file);
                const res = info.file;
                if (res) {
                    console.log("File uploaded successfully", res.response.file);
                    //res.file.path
                    const invoice = {
                        fileId: res.response.file.id,
                        transactionId: transactionId.toString(),
                        accountId: accountId.toString(),
                    };
                    create({
                        resource: 'invoices',
                        values: invoice
                    }, {
                        onSuccess: () => {
                            console.log("Invoice created successfully");
                            invalidate({ resource: 'transactions', invalidates: ["list"] });
                        }
                    });
                }
            } else if (info.file.status === 'error') {
                //message.error(`${info.file.name} file upload failed.`);
                if (info.file.error.status == 401) {
                    // Unauthorized, try refreshing token
                }
                console.log("File upload failed");

            }
        }
    });

    return (
        <List
            canCreate={false}
            title={<>
                {user?.role?.id == UserRole.user && (
                    <CustomerBackButton />
                )}
                {translate("transactions.titles.list")}
            </>}
            breadcrumb={
                <>
                    {user?.role?.id == UserRole.user && (
                        <CustomerBreadcrumb title={translate('actions.list')} />
                    )}
                </>
            }
            resource="transactions"
        >
            <Table {...tableProps} rowKey="id">
                <Table.Column
                    dataIndex={["createdAt"]}
                    title={translate("transactions.fields.createdAt")}
                    render={(value: any) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
                />
                <Table.Column
                    dataIndex="amount"
                    title={translate("transactions.fields.amount")}
                    render={(value: any) => `${value} €`}
                />
                <Table.Column
                    dataIndex="account"
                    title={translate("transactions.fields.account")}
                    render={(value: any) => value?.name}
                />
                <Table.Column
                    title={translate("table.actions")}
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            {record.invoice
                                ? (
                                    <>
                                        <Button
                                            type="primary"
                                            icon={<DownloadOutlined />}
                                            href={record.invoice.file.path}
                                            target="_blank"
                                        >
                                            {translate('transactions.titles.viewInvoice')}
                                        </Button>
                                        {isAdmin && (
                                            <DeleteButton
                                                resource="invoices"
                                                recordItemId={record.invoice.id}
                                                onSuccess={() => 
                                                    invalidate({ resource: 'transactions', invalidates: ["list"] })
                                                }
                                            >
                                                {translate('transactions.titles.deleteInvoice')}
                                            </DeleteButton>
                                        )}
                                    </>
                                )
                                : (
                                    <>
                                        {isAdmin && (
                                            <Upload
                                                {...createUploadProps(record.id, record.account?.id) as UploadProps}
                                            >
                                                <Button icon={<UploadOutlined />}>{translate('transactions.titles.addInvoice')}</Button>
                                            </Upload>
                                        )}
                                    </>
                                )
                            }
                            <ShowButton
                                hideText
                                hidden
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>

        </List>
    );
};
