import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import React, { useState } from 'react';
import { Form, Input, Layout } from "antd";
import FormEditor from "./FormEditor";
const { Header, Content, Footer, Sider } = Layout;


export const FormCreate: React.FC<IResourceComponentsProps> = () => {

    const _t = useTranslate();
    const { form, formProps, saveButtonProps, queryResult } = useForm();


    const setFormCode = (code: string) => {
        console.log("Form code", code);
        form.setFieldValue('schema', code);
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label={_t("forms.fields.name")}
                    name="name"
                    className="w-60"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={_t("forms.fields.schema")}
                    name="schema"
                    hidden
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
            </Form>
            <FormEditor initForm={null} setForm={setFormCode} />
        </Create>
    );
}


