import React, { useEffect, useState } from "react";
import {
    IResourceComponentsProps,
    BaseRecord,
    useTranslate,
} from "@refinedev/core";
import {
    useTable,
    List,
    EditButton,
    ShowButton,
    TagField,
    EmailField,
    DateField,
    TextField,
    DeleteButton,
} from "@refinedev/antd";
import { Table, Space } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const UserList: React.FC<IResourceComponentsProps> = ({
    usersData = null, embedded = false
}) => {
    const _t = useTranslate();
    const { tableProps, tableQueryResult } = useTable({
        syncWithLocation: true,
        resource: "users"
    });
    //const [users, setUsers] = useState<any[]>(tableQueryResult?.data?.data || []);
    const [users, setUsers] = useState<any[]>(usersData || []);

    useEffect(() => {
        if (!usersData) {
            setUsers(tableQueryResult?.data?.data || []);
        } else {
            setUsers(usersData);
            console.log("Users data", usersData);
        }
    }, [tableQueryResult?.data?.data]);

    return (
        <List
            resource="users"
            breadcrumb={!embedded}
        >
            <Table dataSource={users} rowKey="id">
                <Table.Column
                    dataIndex="id"
                    title={_t("users.fields.id")}
                />
                <Table.Column
                    dataIndex="firstName"
                    title={_t("users.fields.firstName")}
                />
                <Table.Column
                    dataIndex="lastName"
                    title={_t("users.fields.lastName")}
                />
                <Table.Column
                    dataIndex={["email"]}
                    title={_t("users.fields.email")}
                    render={(value: any) => <EmailField value={value} copyable />}
                />
                {!embedded && (
                    <Table.Column
                        dataIndex={["account", "name"]}
                        title={_t("users.fields.account")}
                        render={
                            (val: any, record: BaseRecord) => val
                                ? <ShowButton resource="accounts" recordItemId={record.account.id} type="link">{val}</ShowButton>
                                : "-"
                        }
                    ></Table.Column>
                )}
                <Table.Column
                    dataIndex={["role", "name"]}
                    title={_t("users.fields.role")}
                    render={(value: any) => <TextField value={_t(`users.fields.roleValues.${value}`)} />}
                />
                <Table.Column
                    dataIndex={["status", "name"]}
                    title={_t("users.fields.status")}
                    render={(value: any) =>
                        value === "Active"
                            ? <TagField icon={<CheckCircleOutlined />} color="success" value={_t(`users.fields.statusValues.${value.toLowerCase()}`)} />
                            : <TagField icon={<ClockCircleOutlined />} color="error" value={_t(`users.fields.statusValues.${value.toLowerCase()}`)} />
                    }
                />
                <Table.Column
                    title={_t("table.actions")}
                    dataIndex="actions"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <EditButton
                                resource="users"
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                            <ShowButton
                                resource="users"
                                hideText
                                size="small"
                                hidden
                                recordItemId={record.id}
                            />
                            <DeleteButton
                                resource="users"
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
