import { useState } from 'react';
import axios from 'axios';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import { useApiUrl } from '@refinedev/core';

const useOrdersExport = ({ mapData, queryParams }) => {
    const [isLoading, setIsLoading] = useState(false);
    const apiUrl = useApiUrl();

    const triggerExport = async () => {
        setIsLoading(true);

        try {
            const response = await axios.get(apiUrl + '/orders/export?' + queryParams);
            if (mapData) {
                response.data = response.data.map(mapData);
            }

            const csv = Papa.unparse(response.data);
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

            saveAs(blob, 'demandes.csv');
        } catch (error) {
            console.error('Failed to export orders:', error);
        }

        setIsLoading(false);
    };

    return { triggerExport, isLoading };
};

export default useOrdersExport;