import React, { useEffect, useState } from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker, Row, Col, Select } from "antd";
import dayjs from "dayjs";
import { accountTypesValues, billingModesValues, useSelectValues } from "./values";

export const AccountEdit: React.FC<IResourceComponentsProps> = () => {
  
  const translate = useTranslate();
  const { formProps, saveButtonProps, queryResult, form } = useForm();
  const account = queryResult?.data?.data;
  const { accountTypes, billingModes } = useSelectValues();
  const [billingMode, setBillingMode] = useState(account?.billingMode);

  useEffect(() => {
    if (account) {
      setBillingMode(account.billingMode);
    }
  }, [account]);

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical"
        onValuesChange={() => form.validateFields(['credit'])}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label={translate("accounts.fields.id")}
              name={["id"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input readOnly disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
          <Col xs={12} sm={12} md={12}>
            <Form.Item
              label={translate("accounts.fields.name")}
              name={["name"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label={translate("accounts.fields.type")}
              name={["type"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={accountTypes}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label={translate("accounts.fields.legalId")}
              name={["legalId"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
          <Col xs={12} sm={12} md={12}>
            <Form.Item
              label={translate("accounts.fields.address")}
              name={["address"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label={translate("accounts.fields.phone")}
              name={["phone"]}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label={translate("accounts.fields.email")}
              name={["email"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label={translate("accounts.fields.billingMode")}
              name={["billingMode"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={billingModes}
                onChange={(val) => setBillingMode(val)}
              />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label={translate("accounts.fields.credit")}
              name={["credit"]}
              hidden={billingMode !== "prepaid"}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Form.Item
              label={translate("accounts.fields.status")}
              name={["status"]}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                options={[
                  {
                    label: translate("accounts.fields.statusValues.active"),
                    value: "active",
                  },
                  {
                    label: translate("accounts.fields.statusValues.inactive"),
                    value: "inactive",
                  },
                  {
                    label: translate("accounts.fields.statusValues.suspended"),
                    value: "suspended",
                  },
                ]}>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Edit>
  );
};
