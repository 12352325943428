import { useTranslation } from "react-i18next";

export const billingModes = [
    { value: 'postpaid', label: 'postpaid' },
    { value: 'prepaid', label: 'prepaid' },
    { value: 'free', label: 'free' },
];

export function billingModesValues(_t: any) {
    return billingModes.map((mode) => ({
        value: mode.value,
        label: _t(`accounts.fields.billingModeValues.${mode.label}`)
    }));
};


export const accountTypes = [
    { value: 'individual', label: 'individual' },
    { value: 'company', label: 'company' },
];

export function accountTypesValues(_t: any) {
    return accountTypes.map((mode) => ({
        value: mode.value,
        label: _t(`accounts.fields.typeValues.${mode.label}`)
    }));
};

export function useSelectValues() {
    const { t } = useTranslation();
    return {
        accountTypes: accountTypesValues(t),
        billingModes: billingModesValues(t),
    };
}