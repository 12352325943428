import React, { useEffect } from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import {
    Show,
    NumberField,
    TagField,
    TextField,
    DateField,
    EmailField,
} from "@refinedev/antd";
import { Col, Divider, Flex, Row, Typography } from "antd";

export const ProductShow: React.FC<IResourceComponentsProps> = () => {
    const _t = useTranslate();
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const { Text } = Typography;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]}>
                <Col xs={12} sm={8} md={6}>
                    <Flex gap="middle">
                        <Text strong>{_t("products.fields.id")} : </Text>
                        <NumberField value={record?.id} />
                    </Flex>
                </Col>
                <Col xs={12} sm={8} md={6} >
                    <Flex gap="middle">
                        <Text strong>{_t("products.fields.name")} : </Text>
                        <TextField value={record?.name} />
                    </Flex>
                </Col>
                <Col xs={12} sm={8} md={6} >
                    <Flex gap="middle">
                        <Text strong>{_t("products.fields.code")} : </Text>
                        <TextField value={record?.code} />
                    </Flex>
                </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]} className="mt-4">
                <Col xs={12} >
                    <Flex gap="middle">
                        <Text strong>{_t("products.fields.description")} : </Text>
                        <TextField value={record?.description ?? "-"} />
                    </Flex>
                </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]} className="mt-4 hidden">
                <Col xs={12} sm={8} md={6} >
                    <Flex gap="middle">
                        <Text strong>{_t("products.fields.form")} : </Text>
                        <TextField value={record?.form?.id ?? "-"} />
                    </Flex>
                </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]} className="mt-4">
                <Col xs={12} sm={8} md={6} >
                    <Flex gap="middle">
                        <Text strong>{_t("products.fields.group")} : </Text>
                        <TextField value={record?.group ?? "-"} />
                    </Flex>
                </Col>
            </Row>
            <Divider className="mt-12 mb-4" />
            <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]}>
                <Col xs={12} sm={8} md={6} >
                    <Flex gap="middle">
                        <Text strong type="secondary">{_t("fields.createdAt")} : </Text>
                        <DateField value={record?.createdAt} type="secondary" format="DD/MM/YYYY" />
                    </Flex>
                </Col>
                <Col xs={12} sm={8} md={6}>
                    <Flex gap="middle">
                        <Text strong type="secondary">{_t("fields.updatedAt")} : </Text>
                        <DateField value={record?.updatedAt} type="secondary" format="DD/MM/YYYY" />
                    </Flex>
                </Col>
            </Row>
        </Show>
    );
};
