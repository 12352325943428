import React from "react";
import { createRoot } from "react-dom/client";
import 'dayjs/locale/fr'
import App from "./App";
import "./i18n";
import "./index.css";
import dayjs from 'dayjs';
import * as Sentry from "@sentry/react";
import logger from "./logger";

/*window.onerror = (message, source, lineno, colno, error) => {
  logger.error(`Message: ${message}, Source: ${source}, Line: ${lineno}, Column: ${colno}, Error: ${error}`,
    { stack: `${error?.stack}` }
  );
};*/

if (import.meta.env.VITE_USE_SENTRY == "true") {
  Sentry.init({
    dsn: "https://bd43db12e3f474823af8fa4b11a3e07f@o407550.ingest.us.sentry.io/4507208269889536",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/wwww\.ji-consulting\.fr/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
dayjs.locale('fr');

if (import.meta.env.PROD) {
  console.log = () => { };
}

root.render(
  <React.StrictMode>
    <React.Suspense fallback="loading">
      <App />
    </React.Suspense>
  </React.StrictMode>
);
