import { ThemedLayoutV2 } from "@refinedev/antd"
import { Authenticated, CanAccess, ErrorComponent } from "@refinedev/core"
import { AntdInferencer } from "@refinedev/inferencer/antd"
import { Outlet, Route } from "react-router-dom"
import { AppIcon } from "../components/app-icon"
import { AccountList, AccountCreate, AccountEdit, AccountShow } from "../pages/accounts"
import { FormCreate } from "../pages/forms/create"
import { FormEdit } from "../pages/forms/edit"
import { FormShow } from "../pages/forms/show"
import { CustomerOrderCreate } from "../pages/orders/create"
import { ProductCreate } from "../pages/products/create"
import { ProductEdit } from "../pages/products/edit"
import { ProductList } from "../pages/products/list"
import { ProductShow } from "../pages/products/show"
import { UserList } from "../pages/users"
import { UserCreate } from "../pages/users/create"
import { Header } from "../components"
import { FormList } from "../pages/forms/list"
import { CatchAllNavigate, NavigateToResource } from "@refinedev/react-router-v6";
import { ThemedSiderV2 } from "../components/layout/sider"
import { Image } from "antd"
import { ThemedTitleV2 } from "../components/layout/title"
import { OrderList } from "../pages/orders/list"
import { OrderShow } from "../pages/orders/show"
import { authProvider } from "../authProvider"
import { NotFound, Unauthorized } from "../components/errors"
import { TransactionList } from "../pages/transactions/list"
import { UserEdit } from "../pages/users/edit"

export const AdminRoutes = (
    <Route
        element={
            <Authenticated
                key="authenticated-inner"
                fallback={<CatchAllNavigate to="/login" />}
            >
                <CanAccess
                    fallback={<Unauthorized />}
                >
                    <ThemedLayoutV2
                        Header={() => <Header sticky />}
                        Sider={(props) => <ThemedSiderV2 {...props} fixed />}
                        Title={({ collapsed }) => (
                            <ThemedTitleV2
                                collapsed={collapsed}
                                text=""
                                icon={<img src="/images/logo.png" alt="logo" width={80} />}
                            />
                        )}
                    >
                        <Outlet />
                    </ThemedLayoutV2>
                </CanAccess>
            </Authenticated>
        }
    >
        <Route
            path="/"
            index
            element={<NavigateToResource resource="orders" />}
        />
        <Route path="orders">
            <Route index element={<OrderList />} />
            <Route path="create" element={<AntdInferencer />} />
            <Route path="edit/:id" element={<AntdInferencer />} />
            <Route path="show/:id" element={<OrderShow />} />
        </Route>
        <Route path="accounts">
            <Route index element={<AccountList />} />
            <Route path="create" element={<AccountCreate />} />
            <Route path="edit/:id" element={<AccountEdit />} />
            <Route path="show/:id" element={<AccountShow />} />
        </Route>
        <Route path="users">
            <Route index element={<UserList />} />
            <Route path="create" element={<UserCreate />} />
            <Route path="edit/:id" element={<UserEdit />} />
            <Route path="show/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="products">
            <Route index element={<ProductList />} />
            <Route path="create" element={<ProductCreate />} />
            <Route path="edit/:id" element={<ProductEdit />} />
            <Route path="show/:id" element={<ProductShow />} />
        </Route>
        <Route path="forms">
            <Route index element={<FormList />} />
            <Route path="create" element={<FormCreate />} />
            <Route path="edit/:id" element={<FormEdit />} />
            <Route path="show/:id" element={<FormShow />} />
        </Route>
        <Route path="attachments">
            <Route index element={<AntdInferencer />} />
            <Route path="create" element={<AntdInferencer />} />
            <Route path="edit/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="transactions">
            <Route index element={<TransactionList />} />
            <Route path="show/:id" element={<AntdInferencer />} />
        </Route>
        <Route path="*" element={<NotFound />} />
    </Route>
)
