import { useGetIdentity, useOne } from "@refinedev/core";

export const useCredit = () => {
    const { data: user } = useGetIdentity();
    return user?.account?.credit ?? 0;
    /*const account = useOne({
        resource: "accounts",
        id: user?.account?.id,
    });
    return account?.credit ?? 0;
    */
}

