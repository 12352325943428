import { Title } from "@refinedev/antd";
import { useGetIdentity, useLink, useTranslate } from "@refinedev/core";
import { Card, Col, Flex, Row, Space, Typography } from "antd";
import { fullName } from "../../shared/utils";
import { useEffect } from "react";
import { refreshToken } from "../../authProvider";

export const AccountHome = () => {
    const _t = useTranslate();
    const { Title } = Typography;
    const Link = useLink();
    const { data: identity } = useGetIdentity();
    console.log(identity);
    const account = identity?.account?.id;
    const prepaid = identity?.account?.billingMode === 'prepaid';
    const createUrl = `/accounts/${account}/orders/create?form=1`;
    const listUrl = `/accounts/${account}/orders/list`;
    const billingUrl = prepaid
        ? `/accounts/${account}/transactions/list`
        : `/accounts/${account}/invoices/list`;

    useEffect(() => {
        refreshToken();
    }, []);

    return (
        <>
            < Row
                justify="center" align="middle" style={{ minHeight: '60vh' }}
            >
                <Col className="sm:w-100 md:w-3/4 lg:w-3/4 2xl:w-1/2">
                    <div className="text-center mb-16">
                        <img src="/images/logo.png" alt="Refine" width={200} />
                    </div>

                    <Row gutter={16} className="pt-8">
                        <Col span={24} className="text-center">
                            <Flex justify="center" align="middle" gap={32}>
                                <Link to={createUrl}>
                                    <Card className="w-60 py-6"
                                        hoverable
                                    >
                                        <Space direction="vertical" size={16} className="pt-3">
                                            <img src="/images/createorder.png" alt="logo" width={80} />
                                            <Title level={5}>{_t('orders.titles.create')}</Title>
                                        </Space>

                                    </Card>
                                </Link>
                                <Link to={listUrl}>
                                    <Card className="w-60 py-6"
                                        hoverable
                                    >
                                        <Space direction="vertical" size={16}>
                                            <img src="/images/myorders.png" alt="logo" width={80} />
                                            <Title level={5}>{_t('orders.titles.list')}</Title>
                                        </Space>
                                    </Card>
                                </Link>
                                <Link to={billingUrl}>
                                    <Card className="w-60 py-6"
                                        hoverable
                                    >
                                        <Space direction="vertical" size={16} className="pt-6">
                                            <img src="/images/billing.png" alt="logo" width={80} />
                                            <Title level={5}>{_t('orders.titles.billing')}</Title>
                                        </Space>
                                    </Card>
                                </Link>
                            </Flex>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
};