import React, { useEffect } from "react";
import {
    IResourceComponentsProps,
    useShow,
    useTranslate,
} from "@refinedev/core";
import { Show, NumberField, MarkdownField, DateField, TextField } from "@refinedev/antd";
import { Card, Col, Divider, Flex, Form, Row, Typography } from "antd";
import FormRender from "./FormRender";

const { Title } = Typography;

export const FormShow: React.FC<IResourceComponentsProps> = () => {
    const _t = useTranslate();
    const { queryResult } = useShow();
    const { data, isLoading } = queryResult;
    const { Text, Paragraph } = Typography;
    const [schema, setSchema] = React.useState([]);

    const record = data?.data;

    useEffect(() => {
        const record = data?.data;
        if (record) {
            setSchema(JSON.parse(record.schema));
        }
    }, [data]);

    return (
        <Show isLoading={isLoading}>
            <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]}>
                <Col xs={12} sm={8} md={6}>
                    <Flex gap="middle" horizontal>
                        <Text strong>{_t("accounts.fields.id")} : </Text>
                        <NumberField value={record?.id ?? ""} />
                    </Flex>
                </Col>
            </Row>

            <Divider />
            <h3>Preview</h3>


            <Card className="bg-gray-100">
                <Form>
                    <FormRender schema={schema} />
                </Form>
            </Card>

            <Divider className="mt-12 mb-4" />
            <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]}>
                <Col xs={12} sm={8} md={6} >
                    <Flex gap="middle" horizontal>
                        <Text strong type="secondary">{_t("fields.createdAt")} : </Text>
                        <DateField value={record?.createdAt} type="secondary" format="DD/MM/YYYY" />
                    </Flex>
                </Col>
                <Col xs={12} sm={8} md={6}>
                    <Flex gap="middle" horizontal>
                        <Text strong type="secondary">{_t("fields.updatedAt")} : </Text>
                        <DateField value={record?.updatedAt} type="secondary" format="DD/MM/YYYY" />
                    </Flex>
                </Col>

            </Row>
        </Show>
    );
};
