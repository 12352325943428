import { AuthBindings } from "@refinedev/core";
import axios from "axios";

export const TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const TOKEN_EXPIRES_KEY = "tokenExpires";
export const USER_KEY = "user";


export interface ResetPasswordFormTypes {
  password: string;
  hash: string;
}

let isRefreshingToken = false;

const AuthUrls = [
  "/auth/refresh",
  "/auth/email/confirm",
  "/auth/account/register",
  "/auth/email/register",
  "/auth/forgot/password",
  "/auth/register/precheck",
  "/auth/email/login",
];

export function isAuthUrl(url: string): boolean {
  return AuthUrls.some(item => url.includes(item));
}

(function (XHR: any) {
  "use strict";

  var open = XHR.prototype.open;
  var send = XHR.prototype.send;

  XHR.prototype.open = function (method, url, async, user, pass) {
    this._url = url;
    open.call(this, method, url, async, user, pass);
  };

  XHR.prototype.send = function (data) {
    var self = this;
    var oldOnReadyStateChange;
    var url = this._url;
    const token = localStorage.getItem(TOKEN_KEY);
    if (
      token
      && url.includes(import.meta.env.VITE_API_URL)
      && !isAuthUrl(url)
    ) {
      this.setRequestHeader("authorization", "bearer " + token);
      console.log("Setting token to", token);
    }

    function onReadyStateChange() {
      if (self.readyState == 4 /* complete */) {
        /* This is where you can put code that you want to execute post-complete*/
        /* URL is kept in this._url */
        if (
          isTokenExpired()
          && url.includes(import.meta.env.VITE_API_URL)
          && !isAuthUrl(url)
        ) {
          console.log("Token expired, we need to refresh it.", url);
          refreshToken();
        }
      }

      if (oldOnReadyStateChange) {
        oldOnReadyStateChange();
      }
    }

    /* Set xhr.noIntercept to true to disable the interceptor for a particular call */
    if (!this.noIntercept) {
      if (this.addEventListener) {
        this.addEventListener("readystatechange", onReadyStateChange, false);
      } else {
        oldOnReadyStateChange = this.onreadystatechange;
        this.onreadystatechange = onReadyStateChange;
      }
    }

    send.call(this, data);
  }
})(XMLHttpRequest);

export const axiosInstance = axios.create({ baseURL: import.meta.env.VITE_API_URL || "http://localhost:3000/api/v1" });

axiosInstance.interceptors.request.use(function (config) {
  console.log("Requesting", config.url);
  if (!config.url?.includes("/auth/refresh")) {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      //config.headers.Authorization = 'Bearer ' + token;
    }
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

const isTokenExpired = () => {
  const tokenExpires = localStorage.getItem(TOKEN_EXPIRES_KEY);
  if (tokenExpires) {
    //console.log("Token expires", tokenExpires, (new Date()).getTime());
    const expires = parseInt(tokenExpires);
    return expires < (new Date()).getTime();
  }
  return true;
}

export const refreshToken = async () => {

  if (isRefreshingToken) return;

  try {
    const token = localStorage.getItem(REFRESH_TOKEN_KEY);
    console.log("Refresh Token", token);
    if (token) {
      isRefreshingToken = true;
      console.log("Trying to refresh token");
      const response = await axiosInstance.post("/auth/refresh", {}, {
        headers: { Authorization: `Bearer ${token}` }
      });
      localStorage.setItem(TOKEN_KEY, response.data.token);
      localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken);
      localStorage.setItem(TOKEN_EXPIRES_KEY, response.data.tokenExpires);
      localStorage.setItem(USER_KEY, JSON.stringify(response.data.user));
      //console.log("Token refreshed", response.data.token);
      console.log("User", response.data.user);
    } else {
      resetSession();
      window.location.href = '/login';
    }
    isRefreshingToken = false;
  } catch (error) {
    console.error("Refresh token error", error);
    isRefreshingToken = false;
    resetSession();
    window.location.href = '/login';
  }
}


axiosInstance.interceptors.response.use(
  function (response) {
    // If the request succeeds, we don't have to do anything and just return the response
    return response;
  },
  async function (error) {
    if (isTokenExpired()) {
      //console.log("Token expired");
    }
    //console.log("Current token", localStorage.getItem(TOKEN_KEY));
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // We can check for a 401 status code and handle it
    if (error.response && error.response.status === 401
      && !error.config.url?.includes('/login')
      && !error.config.url?.includes('/refresh')) {
      // Clear the access token from local storage
      //refreshToken();
    }
    return Promise.reject(error);
  }
);

const resetSession = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(TOKEN_EXPIRES_KEY);
  localStorage.removeItem(USER_KEY);
}

export const authProvider: AuthBindings = {

  login: async ({ username, email, password }) => {
    if ((username || email) && password) {
      try {
        console.log('Trying to login', email, password);
        const response = await axiosInstance.post("/auth/email/login", {
          email,
          password,
        });
        console.log("Login response", response);
        localStorage.setItem(TOKEN_KEY, response.data.token);
        localStorage.setItem(REFRESH_TOKEN_KEY, response.data.refreshToken);
        localStorage.setItem(TOKEN_EXPIRES_KEY, response.data.tokenExpires);
        localStorage.setItem(USER_KEY, JSON.stringify(response.data.user));
        const user = response.data.user;
        console.log("User successfully logged with role", user.role);
        console.log("User", user);
        let redirecTo = "/";
        if (user?.role?.name === "User")
          redirecTo = "/account-home";
        if (user?.role?.name === "Admin")
          redirecTo = "/";
        console.log("Redirecting to", redirecTo);
        window.location.href = redirecTo;
        return {
          success: false,
          //redirectTo: redirecTo,
        };

      } catch (error) {
        if (error.response) {
          console.error("Login error", error.response.data);
          if (error.response?.data?.errors?.account === "accountNotActive")
            return {
              success: false,
              error: {
                name: "Erreur d'authentification",
                message: "Compte en attente d'activation",
              },
            };

          return {
            success: false,
            error: {
              name: "Erreur d'authentification",
              message: "Email ou mot de passe incorrect",
            },
          };
        }
      }
    }

    console.error("Othre Login error");
    return {
      success: false,
      error: {
        name: "LoginError",
        message: "Email ou mot de passe incorrect",
      },
    };
  },

  logout: async () => {
    resetSession();
    return {
      success: true,
      redirectTo: "/login",
    };
  },

  forgotPassword: async ({ email }) => {
    try {
      await axiosInstance.post("/auth/forgot/password", { email });
      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (error) {
      return {
        success: false,
        error: {
          name: "Erreur d'authentification",
          message: "Invalid email address",
        },
      };
    }
  },

  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },

  getPermissions: async () => {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      try {
        const parsed = JSON.parse(user || "{}");
        //console.log("User data", parsed);
        return parsed.role?.id || '';
      } catch (error) {
        console.error("Error parsing user data", error);
      }
    }
    return '';
  },

  getIdentity: async () => {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      const parsed = JSON.parse(user || "{}");
      //console.log("User data", parsed);
      return parsed;
    }
    return null;
  },

  updatePassword: async ({ password, hash }) => {
    console.log("Update password", password, hash);
    if (!password || !hash) {
      return {
        success: false,
        error: {
          name: "Erreur d'authentification",
          message: "Invalid password or hash",
        },
      };
    }

    try {
      await axiosInstance.post("/auth/reset/password", { password, hash });
      return {
        success: true,
        redirectTo: "/login",
      };
    } catch (error) {
      return {
        success: false,
        error: {
          name: "Erreur d'authentification",
          message: "Invalid password or hash",
        },
      };
    }
  },

  onError: async (error) => {
    console.error("Authentication error", error);
    if (error.response?.status === 401) {
      refreshToken();
    }
    return { error };
  },
};
