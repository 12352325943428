export const generateIniFile = (data: any) => {
    const iniFile =
`[WinOLS]
ClientName=${data.account}
ClientLicenceplace=${data.immatriculation}
VehicleVIN=${data.vin}
VehicleType= 
VehicleProducer=${data.make}
VehicleSeries=${data.model}
VehicleBuild=
VehicleModel=${data.engine}
VehicleModelyear=${data.year}
EcuProducer=${data.calculator}
EcuBuild=${data.prepared}
EcuProdNr=${data.hw}
EcuSoftwareversion=${data.sw}
EngineName=
EngineType=
EngineDisplacement=
OutputPS=${data.power}
OutputKW=
EngineTransmission=${data.gearbox}
ReadingHardware=${data.method}
`;

    return iniFile;
}