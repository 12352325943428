import { ThemedLayoutV2, ThemedTitleV2 } from "@refinedev/antd";
import { Authenticated, CanAccess } from "@refinedev/core";
import { CatchAllNavigate, NavigateToResource } from "@refinedev/react-router-v6";
import { Navigate, Outlet, Route } from "react-router-dom";
import { AppIcon } from "../components/app-icon";
import { AccountHome } from "../pages/home/AccountHome";
import { AntdInferencer } from "@refinedev/inferencer/antd";
import { CustomerOrderCreate } from "../pages/orders/create";
import { Header } from "../components/header";
import { CustomerOrdersList } from "../pages/orders/customerOrdersList";
import { OrderList } from "../pages/orders/list";
import { OrderShow } from "../pages/orders/show";
import { PaymentResult } from "../pages/payments/paymentResult";
import { TransactionList } from "../pages/transactions/list";
import { InvoiceList } from "../pages/invoices/list";

export const CustomerRoutes =
    (
        <Route element={
            <Authenticated
                key="authenticated-inner"
                fallback={<CatchAllNavigate to="/login" />}
            >
                <CanAccess resource="account-home" action="show">
                    <ThemedLayoutV2
                        Header={() => <Header sticky />}
                        Sider={() => <></>}
                        Title={({ collapsed }) => (
                            <ThemedTitleV2
                                collapsed={collapsed}
                                text="Customer"
                                icon={<AppIcon />}
                            />
                        )}
                    >
                        <Outlet />
                    </ThemedLayoutV2>
                </CanAccess>
            </Authenticated>
        }
        >
            <Route
                path="/account-home"
                element={<AccountHome />}
            />
            <Route path="/orders">
                <Route path="create" element={<CustomerOrderCreate />} />
            </Route>
            <Route path="accounts/:id">
                <Route path="orders/create" element={<CustomerOrderCreate />} />
                <Route path="orders/list" element={<OrderList />} />
                <Route path="orders/show/:id" element={<OrderShow />} />
                <Route path="transactions/list" element={<TransactionList />} />
                <Route path="invoices/list" element={<InvoiceList />} />
            </Route>
            <Route path="payments">
                <Route path="result" element={<PaymentResult />} />
            </Route>
        </Route>
    );
