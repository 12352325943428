import {
  DateField,
  DeleteButton,
  EditButton,
  EmailField,
  FilterDropdown,
  List,
  ShowButton,
  TextField,
  useTable,
} from "@refinedev/antd";
import { BaseRecord, HttpError, useTranslate } from "@refinedev/core";
import { Input, Space, Table } from "antd";
import { AccountStatusLabels } from "../orders/components/accountStatusLabels";


interface IAccount {
  id: number;
  name: string;
}

export const AccountList = () => {

  const _t = useTranslate();

  const { tableProps } = useTable<IAccount, HttpError>({
    syncWithLocation: true,
    sorters: {
      initial: [{ field: "id", order: "asc" }],
    },
    filters: {
      initial: [
        {
          field: "name",
          operator: "contains",
          value: "",
        },
      ],
    },
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" sorter={{ multiple: 2 }} />
        <Table.Column dataIndex="name" title={_t("accounts.fields.name")}
          sorter={{ multiple: 1 }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Input placeholder={_t('accounts.fields.name')} />
            </FilterDropdown>
          )}
        />
        <Table.Column dataIndex="type" title={_t("accounts.fields.type")}
          render={(value: any) => <TextField value={_t(`accounts.fields.typeValues.${value}`)} />}
        />
        <Table.Column dataIndex="phone" title={_t("accounts.fields.phone")} />
        <Table.Column
          dataIndex={["email"]}
          title={_t("accounts.fields.email")}
          render={(value: any) => <EmailField value={value} copyable />}
        />
        <Table.Column dataIndex="billingMode" title={_t("accounts.fields.billingMode")}
          render={(value: any) => <TextField value={_t(`accounts.fields.billingModeValues.${value}`)} />}
        />

        <Table.Column
          dataIndex="status"
          title={_t("accounts.fields.status")}
          render={(value: any) => <AccountStatusLabels value={value} />}
          width={100}
          filters={
            [
              { text: _t("accounts.fields.statusValues.draft"), value: "draft" },
              { text: _t("accounts.fields.statusValues.active"), value: "active" },
              { text: _t("accounts.fields.statusValues.inactive"), value: "inactive" },
              { text: _t("accounts.fields.statusValues.suspended"), value: "suspended" },
            ]
          }
          onFilter={(value: any, record: any) => record.status === value}
        />

        <Table.Column
          title={_t("table.actions")}
          dataIndex="actions"
          render={(_, record: BaseRecord) => (
            <Space>
              <EditButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <ShowButton
                hideText
                size="small"
                recordItemId={record.id}
              />
              <DeleteButton
                hideText
                size="small"
                recordItemId={record.id}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
