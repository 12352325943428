import { useNavigation, useTranslate } from "@refinedev/core";
import { Button, Card, Col, Result, Row } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../authProvider";

const ConfirmEmail = () => {

    const _t = useTranslate();
    const { replace } = useNavigation();
    const [success, setSuccess] = useState(false);
    const [params, setParams] = useSearchParams();

    useEffect(() => {
        const fetchData = async () => {
            if (params.get('hash')) {
                const hash = params.get('hash');
                const response = await axiosInstance.post('/auth/email/confirm', { hash });
                setSuccess(true);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            < Row
                justify="center" align="middle" style={{ minHeight: '100vh' }}
            >
                <Col className="sm:w-100 md:w-1/2">
                    <Card className="mt-16 text-center">
                        {success ? (
                            <Result
                                status="success"
                                title={_t('pages.register.confirmEmailMessage')}
                                subTitle={_t('pages.register.confirmEmailSubMessage')}
                            />
                        ) : (
                            <Result
                                status="error"
                                title={_t('pages.register.confirmEmailErrorMessage')}
                                subTitle={_t('pages.register.confirmEmailErrorSubMessage')}
                            />
                        )}
                        <Button
                            type="primary"
                            onClick={() => replace("/login")}
                        >
                            {_t('pages.register.gotoLoginMessage')}
                        </Button>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default ConfirmEmail;
