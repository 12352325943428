import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, DatePicker, Select, InputNumber } from "antd";
import dayjs from "dayjs";

export const ProductCreate: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();

    const { selectProps: formSelectProps } = useSelect({
        resource: "forms",
    });

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label={translate("products.fields.name")}
                    name={["name"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={translate("products.fields.code")}
                    name={["code"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={translate("products.fields.price")}
                    name={["price"]}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <InputNumber min={0} stringMode precision={1} />
                </Form.Item>
                <Form.Item
                    label={translate("products.fields.description")}
                    name={["description"]}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                
                <Form.Item
                    label={translate("products.fields.group")}
                    name={["group"]}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate("products.fields.form")}
                    name={"form"}
                    hidden
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select {...formSelectProps} />
                </Form.Item>
            </Form>
        </Create>
    );
};
