import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import { useCreate, useGetIdentity, useGetLocale, useGo, useLogout, useSetLocale, useTranslate } from "@refinedev/core";
import {
  Avatar,
  Layout as AntdLayout,
  Space,
  Switch,
  theme,
  Typography,
  Menu,
  Dropdown,
  Button,
  Flex,
  Modal,
  Statistic,
  Card,
  Form,
  Input,
  InputNumber,
} from "antd";
import React, { useContext, useEffect } from "react";
import { ColorModeContext } from "../../contexts/color-mode";
import { useTranslation } from "react-i18next";
import { DownOutlined, EuroCircleOutlined, EuroCircleTwoTone, EuroOutlined, RightOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from 'antd';
import Meta from "antd/es/card/Meta";
import { redirect } from "react-router-dom";
import { useCredit } from "../../hooks/account";
import { FormContainerTitle } from "../../pages/forms/components/Container";

const { Text } = Typography;
const { useToken } = theme;

type IUser = {
  id: number;
  name: string;
  avatar: string;
};

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky,
}) => {
  const { token } = useToken();
  const { data: user } = useGetIdentity<IUser>();
  const { mode, setMode } = useContext(ColorModeContext);
  const { mutate: logout } = useLogout();
  const _t = useTranslate();
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  const { mutate: create } = useCreate();
  const currentLocale = locale();

  const [isBuyModalOpen, setIsBuyModalOpen] = React.useState(false);
  const [currentPackage, setCurrentPackage] = React.useState<any>();
  const [creditToBuy, setCreditToBuy] = React.useState(0);

  const accountCredit = useCredit();
  console.log("Account credit", accountCredit);

  const PricePerCredit = 10;

  const handleBuyModalOk = () => {
    //setIsBuyModalOpen(false);
    let credit = creditToBuy;
    let price = creditToBuy * PricePerCredit * 100;
    if (currentPackage) {
      credit = currentPackage.credit;
      price = currentPackage.price * 100;
    }

    create({
      resource: "payments",
      values: {
        credit,
        price,
        accountId: `${user?.account?.id}`,
      },
    }, {
      onSuccess: (res) => {
        console.log("Payment request sent successfully", res.data);
        window.location.replace(res.data?.url);
      },
    })
  };

  const handleBuyModalCancel = () => {
    setIsBuyModalOpen(false);
  };

  const handeleBuyModalOpen = () => {
    setIsBuyModalOpen(true);
  };

  const setCredit = (value: number) => {
    setCreditToBuy(value);
    setCurrentPackage(null);
  }

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === "en" ? "English" : "French"}
        </Menu.Item>
      ))}
    </Menu>
  );

  const headerStyles: React.CSSProperties = {
    backgroundColor: "#F37233",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  if (sticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }


  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={() => logout()}>
          Déconnexion
        </a>
      ),
    }
  ];

  console.log("Account billing mode", user?.account?.billingMode);

  const Packages = [
    {
      id: 1,
      name: "Pack 1",
      price: 10 * PricePerCredit,
      credit: 10
    },
    {
      id: 2,
      name: "Pack 2",
      price: 20 * PricePerCredit,
      credit: 20
    },
    {
      id: 3,
      name: "Pack",
      price: 30 * PricePerCredit,
      credit: 30
    }
  ];

  return (
    <>
      <AntdLayout.Header style={headerStyles}>
        <Flex align="center" justify="center">
          <Space>
            <Dropdown overlay={menu} className="hidden">
              <Button type="link">
                <Space>
                  <Avatar size={16} />
                  {currentLocale === "en" ? "English" : "Français"}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
            <Switch
              checkedChildren="🌛"
              unCheckedChildren="🔆"
              onChange={() => setMode(mode === "light" ? "dark" : "light")}
              defaultChecked={mode === "dark"}
              className="hidden"
            />
            {user?.account?.billingMode === "prepaid" && (
              <Button
                className="text-gray-700"
                style={{ backgroundColor: token.colorPrimaryBg }}
                type="primary"
                icon={<EuroCircleOutlined className="text-gray-700" />}
                onClick={handeleBuyModalOpen}
              >
                <Space>
                  <Text strong>{accountCredit}</Text>
                  <Text strong>|</Text>
                  <Text strong>{_t('credits.titles.buy')}</Text>
                </Space>
              </Button>
            )}
            <Space size="middle" className="ml-4">
              {user?.firstName && <Text strong className="text-gray-50">{user.firstName}</Text>}
              <Dropdown menu={{ items }} placement="bottomRight" arrow>
                <Button type="primary" shape="circle" icon={<UserOutlined />} className="bg-gray-50 text-blue-500">
                </Button>
              </Dropdown>
            </Space>
          </Space>
        </Flex>
      </AntdLayout.Header>
      <Modal
        title={_t('credits.titles.buy')}
        open={isBuyModalOpen}
        onOk={handleBuyModalOk}
        onCancel={handleBuyModalCancel}
        okButtonProps={{ disabled: !currentPackage && creditToBuy === 0 }}
        width={600}
      >
        <Flex justify="flex-start" className="mb-4 hidden">
          <Card bordered={true} className="w-44">
            <Statistic title="Account Balance" value={user?.account?.credit} precision={0} />
          </Card>
        </Flex>

        <Flex align="items-around" justify="space-between" className="pt-12 pb-4">
          {Packages.map((item) => (
            <Card
              key={item.id}
              bordered={true}
              className={currentPackage?.id == item.id ? `shadow-md shadow-gray-400 border-blue-500 w-44 text-center` : "w-44 text-center"}
              hoverable
              onClick={() => setCurrentPackage(item)}
            >
              <Space align="baseline">
                <Typography.Title level={3}>{item.credit}</Typography.Title>
                <Typography.Text type="secondary">Credits</Typography.Text>
              </Space>
              <Typography.Title level={5}>{`${item.price} €`} </Typography.Title>
            </Card>
          ))}
        </Flex>
        <FormContainerTitle title="Ou" />
        <Flex align="center" vertical justify="center" className="pt-0 pb-12">
          <Typography.Title level={5}>{_t('credits.titles.freeAmountHint')}</Typography.Title>

          <Flex justify="center" className="mt-1">
            <InputNumber
              value={creditToBuy}
              onChange={(value) => setCredit(+value)}
              className="w-32 mr-2"
              suffix={_t('credits.credits')}
            />

            <RightOutlined className="mr-2" />

            <InputNumber
              value={creditToBuy * PricePerCredit}
              disabled
              className="w-32"
              suffix={'€'}
            />
          </Flex>

          <Typography.Text className="text-xs mt-1" type="secondary">
            1 {_t('credits.credit')} = {PricePerCredit} €
          </Typography.Text>
        </Flex>

      </Modal>
    </>
  );
};
