import { DownloadOutlined } from "@ant-design/icons";
import { DateField, DeleteButton, List } from "@refinedev/antd";
import { BaseRecord, useGetIdentity, useMany, useOne, useTranslate } from "@refinedev/core";
import { Button, Space, Table } from "antd";
import Column from "antd/es/table/Column";
import { CustomerBreadcrumb } from "../orders/components/customerBreadcrumb";
import { CustomerBackButton } from "../orders/components/customerBackButton";

export const InvoiceList = () => {
    const _t = useTranslate();
    const { data: user } = useGetIdentity();
    const accounts = useMany({ resource: 'auth/my-account', ids:[] });
    const account = accounts?.data?.data;
    //const account = useOne({ resource: 'accounts', id: user?.account?.id });
    const invoices = account?.invoices;

    return (
        <List
            resource="invoices"
            title={<>
                <CustomerBackButton />
                {_t("invoices.titles.list")}
            </>}
            breadcrumb={<CustomerBreadcrumb title={_t('actions.list')} />}
        >

            <Table dataSource={invoices} rowKey="id" size="small" className="max-w-3xl">
                <Column title={_t("invoices.fields.createdAt")} dataIndex="createdAt"
                    render={(value: any) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
                    width={280} />

                <Table.Column
                    title={_t("table.actions")}
                    dataIndex="actions"
                    render={(_, invoice: BaseRecord) => (
                        <Space>
                            <>
                                <Button
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    href={invoice.file.path}
                                    target="_blank"
                                >
                                    {_t('invoices.titles.download')}
                                </Button>
                            </>
                        </Space>
                    )}
                />
            </Table>
        </List>
    )
}