import { useTranslate } from "@refinedev/core";
import dayjs from 'dayjs';

export function generateRandomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function syntaxHighlight(json: string | any) {
    if (typeof json != 'string') {
         json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        var cls = 'number';
        if (/^"/.test(match)) {
            if (/:$/.test(match)) {
                cls = 'key';
            } else {
                cls = 'string';
            }
        } else if (/true|false/.test(match)) {
            cls = 'boolean';
        } else if (/null/.test(match)) {
            cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
    });
}

function useObjectToDescription(data) {
    const translate = useTranslate();
    return Object.keys(data.data)
        .filter((key) => ["form", "createdAt", "updatedAt", "__entity"].includes(key) === false)
        .map((key) => {
            return {
                label: translate(`products.fields.${key}`),
                key: generateRandomString(4),
                children: data.data[key],
            };
        });
}

export function flattenData(data) {
    //console.log("flattenData", data);
    return data.reduce((flat, item) => {
        flat.push(item);
        if (item.children && item.children.length > 0) {
            flat.push(...flattenData(item.children));
        }
        return flat;
    }, []);
}


export function dateSorter(a: string, b: string) {
    console.log("Sorting", dayjs(a).format("DD/MM/YYYY HH:mm"), dayjs(b).format("DD/MM/YYYY HH:mm"));
    const res = dayjs(a).isBefore(dayjs(b));
    console.log("Sort res", res);
    return res;
}

export function fullName(data) {
    return `${data?.firstName} ${data?.lastName}`;
}

export function concatValues(list, field = null) {
    if (!list) return "";
    if (field) {
        return list.map((item) => item[field]).join(", ");
    }
    return list.map((item) => item.value).join(", ");
}