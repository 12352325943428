import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, DatePicker } from "antd";
import dayjs from "dayjs";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult, form } = useForm(
        {
            onMutationError: (data, variables, context, isAutoSave) => {
                const errors = data?.response?.data?.errors;
                if ('email' in errors) {
                    if (errors['email'] === 'emailAlreadyExists')
                    form.setFields([
                        {
                            name: 'email',
                            errors: [translate('users.errors.emailAlreadyExists')],
                        },
                    ]);
                }
            },
        }
    );

    const usersData = queryResult?.data?.data;

    const { selectProps: roleSelectProps } = useSelect({
        resource: "roles",
        defaultValue: usersData?.role?.id,
        optionLabel: "name",
    });

    const { selectProps: accountSelectProps } = useSelect({
        resource: "accounts",
        defaultValue: usersData?.account?.id,
        optionLabel: "name",
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label={translate("users.fields.id")}
                    name={["id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input readOnly disabled />
                </Form.Item>
                <Form.Item
                    label={translate("users.fields.email")}
                    name={["email"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate("users.fields.firstName")}
                    name={["firstName"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={translate("users.fields.lastName")}
                    name={["lastName"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={translate("users.fields.role")}
                    name={["role", "id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...roleSelectProps} />
                </Form.Item>
                <Form.Item
                    label={translate("users.fields.status")}
                    name={["status", "id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select options={[
                        { label: translate('users.fields.statusValues.active'), value: 1 },
                        { label: translate('users.fields.statusValues.inactive'), value: 2 },
                    ]} />

                </Form.Item>
                <Form.Item
                    label={translate("users.fields.account")}
                    name={["account", "id"]}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select {...accountSelectProps} />
                </Form.Item>

            </Form>
        </Edit>
    );
};
