import { Authenticated } from "@refinedev/core"
import { NavigateToResource } from "@refinedev/react-router-v6";
import { Outlet, Route } from "react-router-dom"
import { ForgotPasswordPage } from "../pages/forgotPassword/forgotPassword"
import Register from "../pages/register"
import { ResetPasswordPage } from "../pages/resetPassword/resetPassword"
import ConfirmEmail from "../pages/register/confirmEmail"
import { LoginPage } from "../pages/login/login";
/*
<Authenticated
                key="authenticated-outer"
                fallback={<Outlet />}
            >
                <NavigateToResource />
            </Authenticated>
            */
export const AuthRoutes = (
    <Route
        element={
            <Outlet />
        }
    >
        <Route path="/login"
            element={<LoginPage />}
        />
        <Route path="/register"
            element={<Register />}
        />
        <Route
            path="/forgot-password"
            element={<ForgotPasswordPage />}
        />
        <Route
            path="/reset-password"
            element={<ResetPasswordPage />}
        />
        <Route
            path="/confirm-email"
            element={<ConfirmEmail />}
        />
    </Route>
)