import { Flex } from "antd";

export const Unauthorized = () => {
    return (
        <Flex justify="center" align="middle" style={{ minHeight: '80vh' }} vertical>
            <div className="text-center">
                <h1>Unauthorized</h1>
                <p>You are not authorized to view this page.</p>
            </div>
        </Flex>
    );
}

export const NotFound = () => {
    return (
        <Flex justify="center" align="middle" style={{ minHeight: '80vh' }} vertical>
            <div className="text-center">
                <h1>Not Found</h1>
                <p>Page not found.</p>
            </div>
        </Flex>
    );
}