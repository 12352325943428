import { AccessControlProvider } from "@refinedev/core";
import { authProvider } from "./authProvider";
import { UserRole } from "./models/role";

const AuthUrls = [
    "/login",
    "/register",
    "/confirm-email",
    "/forgot-password",
    "/reset-password",
];

export function isAuthUrl(url: string): boolean {
    return AuthUrls.some(item => url.includes(item));
}

export const accessControlProvider: AccessControlProvider = {
    can: async ({ action, resource, params }) => {

        const role = await authProvider.getPermissions();
        console.log("accessControlProvider - User role", role);

        if (isAuthUrl(window.location.pathname)) {
            return {
                can: true,
            };
        }

        if (!role || role == '') {
            if (window.location.pathname !== "/login")
                window.location.href = "/login";
            return {
                can: false,
                reason: "No roles found",
            };
        }

        if (role == UserRole.admin) {
            /*if (resource == "users" && action == "create") {
                return {
                    can: false,
                };
            }*/
            return {
                can: true,
            };
        }
        if (role == UserRole.user) {
            //console.log("accessControlProvider", action, resource, params);
            console.log("accessControlProvider", params);
            if (
                resource == "account-home"
                || resource == "orders"
                || resource == "transactions"
            ) {
                console.log("accessControlProvider - Access granted", resource, action, params);
                return {
                    can: true,
                };
            } else {
                //window.location.href = "/account-home";
            }
        }

        console.log("accessControlProvider - Access denied", resource, action, params);

        return {
            can: false,
            reason: "Unauthorized",
        };
    },
    options: {
        buttons: {
            enableAccessControl: false,
            hideIfUnauthorized: true,
        }
    }

};

