import React, { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useApiUrl,
  useCreate,
  useInvalidate,
  useShow,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Show,
  NumberField,
  TagField,
  TextField,
  EmailField,
  DateField,
  List,
  DeleteButton,
} from "@refinedev/antd";
import { Button, Card, Col, Divider, Flex, Row, Space, Table, Tabs, TabsProps, Tag, Typography, Upload, UploadProps } from "antd";
import { CheckCircleOutlined, ClockCircleOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import Column from "antd/es/table/Column";
import { AccountStatusLabels } from "../orders/components/accountStatusLabels";
import { useSearchParams } from "react-router-dom";
import { UserList } from "../users";

const { Title, Text } = Typography;

export const AccountShow: React.FC<IResourceComponentsProps> = () => {
  const _t = useTranslate();
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const invalidate = useInvalidate();
  const apiUrl = useApiUrl();
  const { mutate: create } = useCreate();
  const { mutate: update } = useUpdate();
  const [record, setRecord] = useState<any>(data?.data);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeKey, setActiveKey] = useState<string>(searchParams.get('view') ?? 'invoices');
  const [isKeySet, setIsKeySet] = useState(false);

  useEffect(() => {
    setRecord(data?.data);
  }, [data]);

  useEffect(() => {
    if (record?.status === "draft" && !isKeySet) {
      setActiveKey('users');
      setIsKeySet(true);
    }
  }, [record, isKeySet]);

  const createUploadProps = (accountId: number) => ({
    name: 'file',
    action: apiUrl + '/files/upload',
    method: 'POST',
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        //message.success(`${info.file.name} file uploaded successfully`);
        console.log(info.file);
        const res = info.file;
        if (res) {
          console.log("File uploaded successfully", res.response.file);
          //res.file.path
          const invoice = {
            fileId: res.response.file.id,
            accountId: accountId.toString(),
          };
          create({
            resource: 'invoices',
            values: invoice
          }, {
            onSuccess: () => {
              console.log("Invoice created successfully");
              invalidate({ resource: 'accounts', invalidates: ["detail"], id: record?.id })
            }
          });
        }
      } else if (info.file.status === 'error') {
        //message.error(`${info.file.name} file upload failed.`);
        if (info.file.error.status == 401) {
          // Unauthorized, try refreshing token
        }
        console.log("File upload failed");

      }
    }
  });

  const updateAccountStatus = (status: string) => {
    const updatedAccount = {
      ...record,
      status: status,
    };
    update({
      resource: 'accounts',
      values: updatedAccount,
      id: record?.id
    }, {
      onSuccess: () => {
        console.log("Account status updated successfully");
        invalidate({ resource: 'accounts', invalidates: ["detail"], id: record?.id })
      }
    });
  };

  const InvoiceList = () => {
    return (
      <List
        resource="invoices"
        breadcrumb={false}
        headerButtons={
          <Upload
            {...createUploadProps(record?.id) as UploadProps}
          >
            <Button icon={<UploadOutlined />} type="primary">{_t('invoices.titles.create')}</Button>
          </Upload>
        }
      >

        <Table dataSource={record?.invoices} rowKey="id" size="small">
          <Column title={_t("invoices.fields.createdAt")} dataIndex="createdAt"
            render={(value: any) => <DateField value={value} format="DD/MM/YYYY HH:mm" />}
            width={180} />

          <Table.Column
            title={_t("table.actions")}
            dataIndex="actions"
            render={(_, invoice: BaseRecord) => (
              <Space>
                <>
                  <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    href={invoice.file.path}
                    target="_blank"
                  >
                    {_t('invoices.titles.download')}
                  </Button>
                  <DeleteButton
                    resource="invoices"
                    recordItemId={invoice.id}
                    onSuccess={() =>
                      invalidate({ resource: 'accounts', invalidates: ["detail"], id: record?.id })
                    }
                  >
                    {_t('invoices.titles.delete')}
                  </DeleteButton>
                </>
              </Space>
            )}
          />
        </Table>
      </List>
    );
  }

  const tabList = [
    {
      key: 'invoices',
      tab: 'Factures',
    },
    {
      key: 'users',
      tab: 'Utilisateurs',
    },
  ];

  const tabItems: Record<string, React.ReactNode> = {
    'invoices': <InvoiceList />,
    'users': <UserList usersData={record?.users} embedded={true} />,
    //'users': <UserList accountId={record?.id} />,
  };

  return (
    <>
      <Show
        isLoading={isLoading}
      >
        <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]}>
          <Col xs={12} sm={8} md={6}>
            <Flex gap="middle">
              <Text strong>{_t("accounts.fields.id")} : </Text>
              <NumberField value={record?.id ?? ""} />
            </Flex>
          </Col>
        </Row>

        <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]} className="mt-4">
          <Col xs={12} sm={12} md={12} >
            <Flex gap="middle">
              <Text strong>{_t("accounts.fields.name")} : </Text>
              <TextField value={record?.name} />
            </Flex>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Flex gap="middle">
              <Text strong>{_t("accounts.fields.type")} : </Text>
              <TextField value={_t(`accounts.fields.typeValues.${record?.type}`)} />
            </Flex>
          </Col>
          <Col xs={12} sm={8} md={6} >
            <Flex gap="middle">
              <Text strong>{_t("accounts.fields.legalId")} : </Text>
              <TextField value={record?.legalId} />
            </Flex>
          </Col>
          <Col xs={12} sm={12} md={12} >
            <Flex gap="middle">
              <Text strong>{_t("accounts.fields.address")} : </Text>
              <TextField value={record?.address} />
            </Flex>
          </Col>
          <Col xs={12} sm={8} md={6} >
            <Flex gap="middle">
              <Text strong>{_t("accounts.fields.phone")} : </Text>
              <NumberField value={record?.phone ?? ""} />
            </Flex>
          </Col>
          <Col xs={12} sm={8} md={6} >
            <Flex gap="middle">
              <Text strong>{_t("accounts.fields.email")} : </Text>
              <EmailField value={record?.email} />
            </Flex>
          </Col>
          <Col xs={12} sm={8} md={6} >
            <Flex gap="middle">
              <Text strong>{_t("accounts.fields.billingMode")} : </Text>
              <TextField value={_t(`accounts.fields.billingModeValues.${record?.billingMode}`)} />
            </Flex>
          </Col>
          <Col xs={12} sm={8} md={6} >
            <Flex gap="middle">
              <Text strong>{_t("accounts.fields.credit")} : </Text>
              <TextField value={`${record?.credit}`} />
            </Flex>
          </Col>
        </Row>
        <Divider className="mt-12 mb-4" />
        <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]}>
          <Col xs={12} sm={8} md={6} >
            <Flex gap="middle">
              <Text strong type="secondary">{_t("fields.createdAt")} : </Text>
              <DateField value={record?.createdAt} type="secondary" format="DD/MM/YYYY" />
            </Flex>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Flex gap="middle">
              <Text strong type="secondary">{_t("fields.updatedAt")} : </Text>
              <DateField value={record?.updatedAt} type="secondary" format="DD/MM/YYYY" />
            </Flex>
          </Col>
          <Col xs={12} sm={8} md={6}>
            <Flex gap="middle">
              <Text strong type="secondary">{_t("accounts.fields.status")} : </Text>
              <AccountStatusLabels value={record?.status} />
            </Flex>
            {record?.status === "draft" && (
              <Space className="pl-16 mt-2">
                <Button type="primary" size="small"
                  onClick={() => updateAccountStatus('active')}
                >
                  {_t('accounts.titles.activate')}
                </Button>
                <Button danger size="small"
                  onClick={() => updateAccountStatus('inactive')}
                >
                  {_t('accounts.titles.deactivate')}
                </Button>
              </Space>
            )}
          </Col>
        </Row>
      </Show >

      <Divider />

      <Card
        tabList={tabList}
        activeTabKey={activeKey}
        onTabChange={(key) => { setActiveKey(key); setSearchParams({ view: key }) }}
      >
        {tabItems[activeKey]}
      </Card>
    </>
  );
};
