import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, NumberField, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, DatePicker, Select, InputNumber } from "antd";
import dayjs from "dayjs";

export const ProductEdit: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const { formProps, saveButtonProps, queryResult } = useForm();

    const productsData = queryResult?.data?.data;

    const { selectProps } = useSelect({
        resource: "forms",
        optionValue: "id",
        optionLabel: "id",
        defaultValue: productsData?.form?.id,
    });

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label={translate("products.fields.id")}
                    name={["id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input readOnly disabled />
                </Form.Item>
                <Form.Item
                    label={translate("products.fields.name")}
                    name={["name"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={translate("products.fields.code")}
                    name={["code"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label={translate("products.fields.price")}
                    name={["price"]}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <InputNumber
                        min={0}
                        stringMode
                        precision={1}
                    />
                </Form.Item>
                <Form.Item
                    label={translate("products.fields.description")}
                    name={["description"]}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={translate("products.fields.group")}
                    name={["group"]}
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>



                <Form.Item
                    label={translate("products.fields.form")}
                    name={"formId"}
                    initialValue={formProps?.initialValues?.form?.id}
                    hidden
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Select {...selectProps} />
                </Form.Item>

            </Form>
        </Edit>
    );
};
