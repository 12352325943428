import { Form } from "antd";
import { Field } from "./components/Field";
import { useEffect, useState } from "react";
import { InputField } from "./components/InputField";
import { ContainerField } from "./components/Container";

const FormRender = ({ schema }) => {

    const [fields, setFields] = useState([]);
    const [form] = Form.useForm();
    
    useEffect(() => {
        const comps = schema.map((comp: any) => {
            if (comp.formTag === 'InputField') {
                return InputField.fromJSON(comp);
            }
            if (comp.formTag === 'ContainerField') {
                return ContainerField.fromJSON(comp);
            }
        });
        setFields(comps);
    }, [schema]);


    return (
        <>
            {fields.map((field: Field, index) => field.render())}
        </>
    );
}

export default FormRender;