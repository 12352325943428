export const resources = [
    {
      name: "accounts",
      list: "/accounts",
      create: "/accounts/create",
      edit: "/accounts/edit/:id",
      show: "/accounts/show/:id",
    },
    {
      name: "users",
      list: "/users",
      create: "/users/create",
      edit: "/users/edit/:id",
      show: "/users/show/:id",
    },
    {
      name: "products",
      list: "/products",
      create: "/products/create",
      edit: "/products/edit/:id",
      show: "/products/show/:id",
    },
    {
      name: "forms",
      list: "/forms",
      create: "/forms/create",
      edit: "/forms/edit/:id",
      show: "/forms/show/:id",
    },
    {
      name: "orders",
      list: "/orders",
      create: "/orders/create",
      edit: "/orders/edit/:id",
      show: "/orders/show/:id",
    },
    {
      name: "attachments",
      list: "/attachments",
      create: "/attachments/create",
      edit: "/attachments/edit/:id",
      show: "/attachments/show/:id",
    },
    {
      name: "messages",
      list: "/messages",
      create: "/messages/create",
      edit: "/messages/edit/:id",
      show: "/messages/show/:id",
    },
    {
      name: "payments",
      create: "/payments/create"
    },
    {
      name: "transactions",
      list: "/transactions",
      show: "/transactions/show/:id",
    },
  ];